<template>
  <div class="popup">
    <h4 class="popup__title">Просмотр файла "<span class="name">{{ file.filename }}</span>"</h4>

<!--    <iframe :src="`${baseUrl}${file.file_path}`" width='100%' height='565px' frameborder='0'> </iframe>-->
    <div class="file">
      <table v-html="fileContent"></table>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";

import XLSX from 'xlsx'

export default {
  name: "viewFile",
  mixins: [popups],
  data: () => ({
    fileContent: []
  }),
  computed: {
    ...mapState({
      file: state => state.popups.file,
    }),
    baseUrl() {
      return `${process.env.VUE_APP_URL}`
    }
  },
  methods: {
    ...mapActions({
      getFileByPath: 'getFileByPath',
      downloadXLSXFile: 'downloadXLSXFile',
    }),
  },
  async mounted() {
    let data = new Uint8Array(this.file.res.data)
    let wb = XLSX.read(data, {type:"array"})
    const firstSheetName = wb.SheetNames[0]
    const first_worksheet = wb.Sheets[firstSheetName]
    this.fileContent = XLSX.utils.sheet_to_html(first_worksheet, {header: '', footer: ''});
  }
}
</script>

<style lang="scss">
@import "../../styles/variables";
.file {
  height: 100%;
  width: 100%;
  padding: 7px;
  background: $back;
  border-radius: 12px;
  overflow: auto;

  table {
    td {
      padding: 5px;
      border: 1px solid #6DB534;
    }
  }
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";
.popup {
  width: unset;
  max-width: 90%;
  min-width: 40%;
  max-height: 90%;

  .popup__title {
    text-align: left;

    .name {
      color: $green;
    }
  }

  .file {
    height: 100%;
    width: 100%;
    padding: 7px;
    background: $back;
    border-radius: 12px;
    overflow: auto;

    table {
      td {
        padding: 5px;
        border: 1px solid #6DB534;
      }
    }
  }
}
</style>
