<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 v-if="isAddTransactions" class="popup__title">Догрузить транзакции</h4>
    <h4 v-else class="popup__title">Обновить транзакции</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Выбор периода
        </div>
        <div class="input">
          <VueDatePicker
              :teleport="true"
              ref="dateRange"
              v-model="date"
              range
              :enable-time-picker="false"
              :month-picker="false"
              :format="'dd.MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              @update:model-value="setDateRange"
              :clearable="false"
          >
            <template #input-icon>
              <img class="input-slot-image" src="../../assets/datepicker-icon.svg" alt="d"/>
            </template>
          </VueDatePicker>
        </div>
      </div>

      <div class="form__item">
        <div class="label">
          Тип интеграции
        </div>
        <div class="input">
          <v-select :options="integrationsArr"
                    label="name"
                    v-model="integration"
                    :reduce="option => option.id"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableSubmitButton" class="button btn-primary" @click="confirmEdit()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
  name: "updateTransactionsPopup",
  mixins: [popups],
  components: {
    VueDatePicker,
    vSelect
  },
  data: () => ({
    loading: false,
    spinner: true,
    range: null,
    date: null,
    integration: null,
    integrationsArr: []
  }),
  computed: {
    ...mapState({
      transaction: state => state.popups.transaction,
      isAddTransactions: state => state.popups.isAddTransactions,
      integrations: state => state.admin.integrations,
    }),
    disableSubmitButton() {
      return !this.date
    }
  },
  methods: {
    ...mapActions({
      editTransaction: 'editTransaction',
      updateTransactions: 'updateTransactions',
      getIntegrations: 'getIntegrations',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setDateRangeStore: 'SET_DATE_RANGE',
    }),
    async setDateRange() {
      //this.loading = true;
      if (this.date[0] === null || this.date[1] === null) {
        setTimeout(() => {
          this.$refs.dateRange.openMenu();
        }, 500)
        return;
      }
      this.range = {
        startDate: `${this.date[0].getFullYear()}-${String(this.date[0].getMonth() + 1).padStart(2, '0')}-${String(this.date[0].getDate()).padStart(2, '0')}`,
        endDate: `${this.date[1].getFullYear()}-${String(this.date[1].getMonth() + 1).padStart(2, '0')}-${String(this.date[1].getDate()).padStart(2, '0')}`,
      }
      //this.setDateRangeStore(this.range);
      console.log(this.range)
      //this.loading = false;
    },
    async confirmEdit() {
      this.loading = true

      let data = {
        range: this.range,
        integration: this.integration,
        update_mode: !this.isAddTransactions
      }

      await this.updateTransactions(data)

      this.loading = false
      //this.closeAll()
    }
  },
  async mounted() {
    if (this.transaction !== null) {
      this.txEdit.original_fuel_provider_name = this.transaction.original_fuel_provider_name;
      this.txEdit.original_fuel_type_name = this.transaction.original_fuel_type_name;
      this.txEdit.original_region_name = this.transaction.original_region_name;
      this.txEdit.partner = this.transaction.partner;
    }
    if (!this.integrations || this.integrations.length == 0) {
      await this.getIntegrations();
    }
    this.integrations.forEach(item => {
      if (item.id <= 2) {
        this.integrationsArr.push(item)
      }
    })
  }
}
</script>

<style lang="scss">
.input .v-select .vs__search {
  pointer-events: none;
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";
.dp__input {
  border-radius: 6px;
  border: 1px solid $back !important;
  --dp-border-color: $back !important;
}

.popup .form__item .label {
  min-width: 120px;
}

.checkbox-cont {
  //padding: 0 10px;
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  color: #1A1A18;
  font-feature-settings: 'liga' off, 'clig' off;
  //font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
</style>
