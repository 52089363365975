<template>
  <div class="table-container">
    <table class="fixed-header-table">
      <thead>
      <tr>
        <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, rowIndex) in data" :key="rowIndex">
        <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: ['Header 1', 'Header 2', 'Header 3', 'Header 4', 'Header 5', 'Header 6', 'Header 7', 'Header 8', 'Header 9', 'Header 10'],
      data: [
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        ['Row 1, Cell 1', 'Row 1, Cell 2', 'Row 1, Cell 3', 'Row 1, Cell 4', 'Row 1, Cell 5', 'Row 1, Cell 6', 'Row 1, Cell 7', 'Row 1, Cell 8', 'Row 1, Cell 9', 'Row 1, Cell 10'],
        ['Row 2, Cell 1', 'Row 2, Cell 2', 'Row 2, Cell 3', 'Row 2, Cell 4', 'Row 2, Cell 5', 'Row 2, Cell 6', 'Row 2, Cell 7', 'Row 2, Cell 8', 'Row 2, Cell 9', 'Row 2, Cell 10'],
        ['Row 3, Cell 1', 'Row 3, Cell 2', 'Row 3, Cell 3', 'Row 3, Cell 4', 'Row 3, Cell 5', 'Row 3, Cell 6', 'Row 3, Cell 7', 'Row 3, Cell 8', 'Row 3, Cell 9', 'Row 3, Cell 10'],
        ['Row 4, Cell 1', 'Row 4, Cell 2', 'Row 4, Cell 3', 'Row 4, Cell 4', 'Row 4, Cell 5', 'Row 4, Cell 6', 'Row 4, Cell 7', 'Row 4, Cell 8', 'Row 4, Cell 9', 'Row 4, Cell 10'],
        // Add more rows as needed
      ]
    };
  }
};
</script>

<style scoped>
.table-container {
  max-height: 400px; /* Adjust height as needed */
  overflow: auto;
  border: 1px solid #ddd;
}

.fixed-header-table {
  width: 100%; /* Adjust to fit your layout */
  border-collapse: collapse;
}

.fixed-header-table th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  z-index: 2;
  text-align: left;
  padding: 8px;
}

.fixed-header-table th,
.fixed-header-table td {
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap; /* Prevent text wrapping */
}

.fixed-header-table tbody tr:hover {
  background-color: #f1f1f1;
}
</style>
