<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title middle">{{ card.status.id === 1 || card.status.id === 3 ? 'Разблокировка топливной карты' : 'Блокировка топливной карты' }}</h4>

    <div class="info">
      <div class="left">
        Номер карты:
      </div>
      <div class="right">
        {{card.number}}
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="suspendCard()">{{ card.status.id === 1 || card.status.id === 3 ? 'Разблокировать' : 'Блокировать' }}</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "suspendCardPopup",
  mixins: [popups],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      card: state => state.popups.card,
    })
  },
  methods: {
    ...mapActions({
      deleteCard: 'deleteCard',
      bunchChangeCardsStatus: 'bunchChangeCardsStatus'
    }),
    ...mapMutations({
      setActionPopup: 'SET_ACTION_POPUP',
    }),
    async suspendCard() {
      this.loading = true
      // await this.deleteCard(this.card.id)
      let data = {
        action: this.card.status.id === 1 || this.card.status.id === 3 ? 2 : 3,
        cards_ids: [this.card.id]
      }
      await this.bunchChangeCardsStatus(data)
      this.loading = false
      //this.closeAll()
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.info {
  width: 100%;
  display: flex;
  border-bottom: 1px solid $back;
  margin-bottom: 27px;

  .left {
    display: flex;
    width: 100px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    color: $grey-1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }

  .right {
    display: flex;
    padding: 10px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    color: $black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}
</style>
