import { createStore } from 'vuex'

import user from "./modules/user/index";
import global from "./modules/global/index";
import popups from "./modules/popups/index";
import customer from "./modules/customer/index";
import admin from "./modules/admin/index";
import references from "./modules/references";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    global,
    popups,
    customer,
    admin,
    references
  }
})
