<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Редактирование платежа</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Сумма руб.
        </div>
        <div class="input">
          <input type="text" v-model="paymentEdit.sum">
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableSubmitButton" class="button btn-primary" @click="confirmEdit()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
  name: "editPaymentPopup",
  mixins: [popups],
  components: {
    VueDatePicker,
    vSelect
  },
  data: () => ({
    loading: false,
    spinner: true,
    paymentEdit: {
      sum: 0
    }
  }),
  computed: {
    ...mapState({
      payment: state => state.popups.payment,
    }),
    disableSubmitButton() {
      return !this.paymentEdit.sum
    }
  },
  methods: {
    ...mapActions({
      editPayment: 'editPayment',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
    }),
    async confirmEdit() {
      this.loading = true

      await this.editPayment({id: this.payment.id, data: this.paymentEdit})

      this.loading = false
      //this.closeAll()
    }
  },
  mounted() {
    if (this.payment !== null) {
      this.paymentEdit.sum = this.payment.sum;
    }
  }
}
</script>

<style lang="scss">
.input .v-select .vs__search {
  pointer-events: none;
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";
.dp__input {
  border-radius: 6px;
  border: 1px solid $back !important;
  --dp-border-color: $back !important;
}

.popup .form__item .label {
  min-width: 120px;
}

.checkbox-cont {
  //padding: 0 10px;
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  color: #1A1A18;
  font-feature-settings: 'liga' off, 'clig' off;
  //font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
</style>
