import * as type from './types';
import actions from './actions';
import api from '../../../api';

const state = {
  cards: null,
  payments: null,
  customers: null,
  analytics: null,
  managers: [],
  manager_dashboard: null,
  accountant_dashboard: null,
  operations_report: null,
  revise_report: null,
  transaction_files: null,
  prices_files: null,
  prices_gold_files: null,
  prices_partner_files: null,
  cardStatuses: null,
  surety_files_to_remove: [],
  surety_files_to_add: [],
  supply_files_to_remove: [],
  supply_files_to_add: [],
  cardsSelect: [],
  integrations: [],
    goldStations: [],
    partnerStations: [],
  paymentIntegrations: [],
  cardCatalog: [],
  reconciliationReport: [],
};

const mutations = {
    SET_CARDS(state, payload) {
        state.cards = payload
    },
    SET_PAYMENTS(state, payload) {
        state.payments = payload
    },
    SET_CUSTOMERS(state, payload) {
        state.customers = payload
    },
    SET_ANALYTICS(state, data) {
        console.log('SET_ANALYTICS')
        console.log(data)
        state.analytics = data
    },
    SET_REVISE_REPORT(state, payload) {
        console.log('SET_REVISE_REPORT')
        console.log(payload)
        state.revise_report = payload
    },
    SET_MANAGERS(state, payload) {
        state.managers = payload
    },
    SET_MANAGER_DASHBOARD(state, payload) {
        state.manager_dashboard = payload
    },
    SET_ACCOUNTANT_DASHBOARD(state, payload) {
        state.accountant_dashboard = payload
    },
    SET_OPERATIONS_REPORT(state, payload) {
        state.operations_report = payload
    },
    SET_TRANSACTION_FILES(state, payload) {
        state.transaction_files = payload
    },
    SET_PRICES_FILES(state, payload) {
        state.prices_files = payload
    },
    SET_PRICES_GOLD_FILES(state, payload) {
        state.prices_gold_files = payload
    },
    SET_PRICES_PARTNER_FILES(state, payload) {
        state.prices_partner_files = payload
    },
    SET_CARD_STATUSES(state, payload) {
        state.cardStatuses = payload
    },
    SET_SURETY_FILES_TO_REMOVE(state, payload) {
        state.surety_files_to_remove = payload
    },
    SET_SUPPLY_FILES_TO_REMOVE(state, payload) {
        state.supply_files_to_remove = payload
    },
    PUSH_TO_SURETY_FILES_TO_ADD(state, payload) {
        state.surety_files_to_add.push(payload)
    },
    PUSH_TO_SUPPLY_FILES_TO_ADD(state, payload) {
        state.supply_files_to_add.push(payload)
    },
    SET_SURETY_FILES_TO_ADD(state, payload) {
        state.surety_files_to_add = payload
    },
    SET_SUPPLY_FILES_TO_ADD(state, payload) {
        state.supply_files_to_add = payload
    },
    REMOVE_SURETY_FILE_FROM_LIST_TO_ADD(state, payload) {
        state.surety_files_to_add = state.surety_files_to_add.filter(item => item.id !== payload.id)
    },
    REMOVE_SUPPLY_FILE_FROM_LIST_TO_ADD(state, payload) {
        state.supply_files_to_add = state.supply_files_to_add.filter(item => item.id !== payload.id)
    },
    SET_CARDS_SELECT(state, payload) {
        state.cardsSelect = payload
    },
    SET_INTEGRATIONS(state, payload) {
        state.integrations = payload
    },
    SET_GOLD_STATIONS(state, payload) {
        state.goldStations = payload
        state.goldStations.forEach(item => {
            item['numberAddress'] = `${item.station_number} (${item.address})`
        })
    },
    SET_PARTNER_STATIONS(state, payload) {
        state.partnerStations = payload
      state.partnerStations.forEach(item => {
        item['nameRegion'] = `${item.name} (${item.region})`
      })
    },
    SET_PAYMENT_INTEGRATIONS(state, payload) {
        state.paymentIntegrations = payload
    },
    SET_CARD_CATALOG(state, payload) {
        state.cardCatalog = payload
    },
    SET_RECONCILIATION_REPORT(state, payload) {
        state.reconciliationReport = payload
    },
};

const getters = {
};

export default {
    state,
    mutations,
    actions,
    getters,
};
