import * as type from './types';
import api from '../../../api';

const actions = {
    showError({commit}, data) {
        commit('TOGGLE_OVERLAY', true);
        commit('SET_ERROR_TEXT', data.errorMessage);
        if (data.isSuccess) {
            commit('SET_IS_SUCCESS', data.isSuccess);
        } else {
            commit('SET_IS_SUCCESS', false);
        }

        setTimeout(() => {
            commit('SHOW_ERROR_POPUP', true);
        },500)

    }
};

export default actions;
