<template>
  <div class="page-container">
    <PageHeader title="Уведомления" icon="bell-active.svg" />

    <div class="page-content">
      <div class="notifications-filters">
        <div class="tabs">
          <button :class="{ active: activeTab === 'all' }" @click="activeTab = 'all'">Все</button>
          <button :class="{ active: activeTab === 'unread' }" @click="activeTab = 'unread'">
            Непрочитанные
          </button>
          <button :class="{ active: activeTab === 'read' }" @click="activeTab = 'read'">
            Прочитанные
          </button>
        </div>

        <button v-if="userRole !== 1" class="actions-toggle" @click="openNotificationSettings">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <circle cx="7" cy="7" r="1.75" stroke="#6DB534" />
            <path
              d="M2.95025 3.24558C2.37994 3.86028 1.96581 4.58976 1.72468 5.3713L2.79596 5.98981C3.57374 6.43886 3.57374 7.56148 2.79596 8.01053L1.72363 8.62964C1.84338 9.01612 2.00775 9.39536 2.21858 9.76053C2.4294 10.1257 2.67564 10.4577 2.95046 10.7546L4.02281 10.1355C4.80059 9.68641 5.77281 10.2477 5.77281 11.1458V12.3829C6.57021 12.5648 7.40902 12.5709 8.22652 12.3844L8.22652 11.1459C8.22652 10.2478 9.19874 9.68645 9.97652 10.1355L11.0491 10.7548C11.6194 10.1401 12.0335 9.41057 12.2747 8.62903L11.2033 8.01051C10.4256 7.56145 10.4256 6.43883 11.2033 5.98978L12.2757 5.37065C12.156 4.98419 11.9916 4.60496 11.7808 4.2398C11.5699 3.87463 11.3237 3.54266 11.0489 3.24572L9.97652 3.86484C9.19874 4.31389 8.22652 3.75257 8.22652 2.85447V1.61746C7.42912 1.43552 6.59031 1.42942 5.77281 1.61598L5.77281 2.85446C5.77281 3.75256 4.80059 4.31387 4.02281 3.86482L2.95025 3.24558Z"
              stroke="#6DB534"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <div v-if="userRole === 1" class="wrap">
          <button class="actions-toggle" @click="actionsPopupShown = true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <circle cx="7" cy="7" r="1.75" stroke="#6DB534" />
              <path
                d="M2.95025 3.24558C2.37994 3.86028 1.96581 4.58976 1.72468 5.3713L2.79596 5.98981C3.57374 6.43886 3.57374 7.56148 2.79596 8.01053L1.72363 8.62964C1.84338 9.01612 2.00775 9.39536 2.21858 9.76053C2.4294 10.1257 2.67564 10.4577 2.95046 10.7546L4.02281 10.1355C4.80059 9.68641 5.77281 10.2477 5.77281 11.1458V12.3829C6.57021 12.5648 7.40902 12.5709 8.22652 12.3844L8.22652 11.1459C8.22652 10.2478 9.19874 9.68645 9.97652 10.1355L11.0491 10.7548C11.6194 10.1401 12.0335 9.41057 12.2747 8.62903L11.2033 8.01051C10.4256 7.56145 10.4256 6.43883 11.2033 5.98978L12.2757 5.37065C12.156 4.98419 11.9916 4.60496 11.7808 4.2398C11.5699 3.87463 11.3237 3.54266 11.0489 3.24572L9.97652 3.86484C9.19874 4.31389 8.22652 3.75257 8.22652 2.85447V1.61746C7.42912 1.43552 6.59031 1.42942 5.77281 1.61598L5.77281 2.85446C5.77281 3.75256 4.80059 4.31387 4.02281 3.86482L2.95025 3.24558Z"
                stroke="#6DB534"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div v-click-outside="onClickOutside" v-if="actionsPopupShown" class="actions-popup">
            <button class="action-btn" @click="openNotificationSettings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle cx="8" cy="8" r="2" stroke="#B0B1B2" />
                <path
                  d="M3.37256 3.70896C2.72077 4.41147 2.24748 5.24517 1.9719 6.13835L3.19622 6.84522C4.08511 7.35842 4.08511 8.64142 3.19622 9.15462L1.9707 9.86217C2.10756 10.3039 2.29541 10.7373 2.53636 11.1546C2.7773 11.5719 3.05872 11.9513 3.37279 12.2907L4.59833 11.5831C5.48722 11.0699 6.59833 11.7114 6.59833 12.7378V14.1516C7.50964 14.3595 8.46829 14.3665 9.40257 14.1533L9.40257 12.7379C9.40257 11.7115 10.5137 11.07 11.4026 11.5832L12.6284 12.2909C13.2802 11.5884 13.7534 10.7547 14.029 9.86147L12.8047 9.15458C11.9158 8.64138 11.9158 7.35838 12.8047 6.84518L14.0302 6.13761C13.8934 5.69594 13.7055 5.26253 13.4646 4.84521C13.2236 4.42787 12.9422 4.04848 12.6281 3.70912L11.4026 4.41668C10.5137 4.92988 9.40258 4.28838 9.40258 3.26198V1.84824C8.49126 1.64031 7.53262 1.63334 6.59833 1.84655L6.59833 3.26196C6.59833 4.28836 5.48722 4.92986 4.59833 4.41666L3.37256 3.70896Z"
                  stroke="#B0B1B2"
                  stroke-linejoin="round"
                />
              </svg>
              Настройка уведомлений
            </button>
            <button class="action-btn" @click="openAdminNotificationSettings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle cx="8" cy="8" r="2" stroke="#B0B1B2" />
                <path
                  d="M3.37256 3.70896C2.72077 4.41147 2.24748 5.24517 1.9719 6.13835L3.19622 6.84522C4.08511 7.35842 4.08511 8.64142 3.19622 9.15462L1.9707 9.86217C2.10756 10.3039 2.29541 10.7373 2.53636 11.1546C2.7773 11.5719 3.05872 11.9513 3.37279 12.2907L4.59833 11.5831C5.48722 11.0699 6.59833 11.7114 6.59833 12.7378V14.1516C7.50964 14.3595 8.46829 14.3665 9.40257 14.1533L9.40257 12.7379C9.40257 11.7115 10.5137 11.07 11.4026 11.5832L12.6284 12.2909C13.2802 11.5884 13.7534 10.7547 14.029 9.86147L12.8047 9.15458C11.9158 8.64138 11.9158 7.35838 12.8047 6.84518L14.0302 6.13761C13.8934 5.69594 13.7055 5.26253 13.4646 4.84521C13.2236 4.42787 12.9422 4.04848 12.6281 3.70912L11.4026 4.41668C10.5137 4.92988 9.40258 4.28838 9.40258 3.26198V1.84824C8.49126 1.64031 7.53262 1.63334 6.59833 1.84655L6.59833 3.26196C6.59833 4.28836 5.48722 4.92986 4.59833 4.41666L3.37256 3.70896Z"
                  stroke="#B0B1B2"
                  stroke-linejoin="round"
                />
              </svg>
              Блокировка уведомлений
            </button>
          </div>
        </div>
      </div>
      <TableComponent
        :columns="columns"
        :rows="notificationsFormatted"
        :noData="!notificationsFormatted.length"
        :isLoading="false"
        :actionType="'notifications'"
        :isBordered="true"
        :totalCount="totalCount"
        :pageSize="pageSize"
        :pageCount="pageCount"
        :currentPage="Number(currentPage)"
        @regetData="regetData($event)"
      />
<!--      <div v-else class="no-data">-->
<!--        <p>У вас нет уведомлений</p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import unreadIcon from '@/assets/notifications/unread.svg'
  import readIcon from '@/assets/notifications/read.svg'

  export default {
    name: 'NotificationsPage',
    data() {
      return {
        totalCount: 0,
        pageSize: 0,
        pageCount: 0,
        currentPage: 0,
        tableColumns: [
          {
            field: 'status',
            filter_name: 'status',
            filter_value: '',
            order: '',
            no_filter: true,
            v_html: true
          },
          {
            name: 'Наименование',
            field: 'content',
            filter_name: 'content',
            filter_value: '',
            order: '',
            multiple_search: true,
            search_items: []
            // no_filter: true
          },
          {
            name: 'Клиент',
            field: 'customer',
            filter_name: 'customer_id',
            filter_value: '',
            order: '',
            multiple: true,
            // no_filter: true
          },
          {
            name: 'Дата',
            field: 'created_at',
            filter_name: 'notification.created_at',
            filter_value: '',
            order: '',
            date_type: true,
            // no_filter: true
          }
        ],
        activeTab: 'all',
        isSettingsOpen: false,
        actionsPopupShown: false
      }
    },
    computed: {
      ...mapState({
        notifications: (state) => state.global.notifications,
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
      }),
      // tableColumnsFiltered() {
      //   let columns = this.tableColumns.filter((column) => {
      //     if (column.field !== 'customer' && this.userRole !== 1) {
      //       return column
      //     } else if (this.userRole === 1) {
      //       return column
      //     }
      //   })
      //   this.setColumns(columns)
      //   return columns;
      // },
      filteredNotifications() {
        if (!this.notifications) {
          return [];
        }
        switch (this.activeTab) {
          case 'unread':
            return this.notifications?.data.filter((notification) => !notification.red)
          case 'read':
            return this.notifications?.data.filter((notification) => notification.red)
          default:
            return this.notifications?.data
        }
      },
      notificationsFormatted() {
        return this.filteredNotifications.map((notification) => ({
          status: notification.red
            ? '<img src="' + readIcon + '" alt="read" />'
            : '<img src="' + unreadIcon + '" alt="unread" />',
          customer: notification.customer?.employee || '',
          content: notification.content,
          created_at: this.formatDate(notification.created_at),
          is_unread: !notification.red,
          id: notification.id
        }))
      }
    },
    methods: {
      ...mapActions({
        getNotifications: 'getNotifications',
        getMyUser: 'getMyUser',
      }),
      ...mapMutations({
        showNotificationSettings: 'SHOW_NOTIFICATION_SETTINGS',
        showAdminNotificationSettings: 'SHOW_ADMIN_NOTIFICATION_SETTINGS',
        toggleOverlay: 'TOGGLE_OVERLAY',
        setColumns: 'SET_COLUMNS',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      formatDate(dateString) {
        if (!dateString) return ''
        const date = new Date(dateString)
        return date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      },
      async regetData(data) {
        console.log(data)

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'perPage',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getNotifications().then(res => {
          this.totalCount = this.notifications.total_count
          this.pageSize = this.notifications.page_size
          this.pageCount = this.notifications.page_count
          this.currentPage = this.notifications.current_page
        })
      },
      onClickOutside() {
        this.actionsPopupShown = false
      },
      openNotificationSettings() {
        this.actionsPopupShown = false
        this.toggleOverlay(true)
        this.showAdminNotificationSettings(false)
        this.showNotificationSettings(true)
      },
      openAdminNotificationSettings() {
        this.actionsPopupShown = false
        this.toggleOverlay(true)
        this.showAdminNotificationSettings(true)
        this.showNotificationSettings(true)
      }
    },
    mounted() {
      this.getNotifications().then(res => {
        this.totalCount = this.notifications.total_count
        this.pageSize = this.notifications.page_size
        this.pageCount = this.notifications.page_count
        this.currentPage = this.notifications.current_page
      })
      this.getMyUser().then(res => {
        let columns = this.tableColumns.filter((column) => {
          if (column.field !== 'customer' && this.userRole !== 1) {
            return column
          } else if (this.userRole === 1) {
            return column
          }
        })
        this.setColumns(columns)
        this.resetPaginationFilters()
      })
    }
  }
</script>

<style scoped lang="scss">
  .notifications-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 24px;

    .tabs {
      display: flex;
      //gap: 8px;
      height: 32px;

      button {
        padding: 8px 16px;
        border: none;
        background: transparent;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 0;

        &:first-child {
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          border-radius: 0px 6px 6px 0px;
        }

        border: 1px solid rgba(109, 181, 52, 0.24);
        background: rgba(109, 181, 52, 0.12);

        &.active {
          border-radius: 6px 0px 0px 6px;
          border-top: 1px solid rgba(109, 181, 52, 0.24);
          border-bottom: 1px solid rgba(109, 181, 52, 0.24);
          border-left: 1px solid rgba(109, 181, 52, 0.24);
          background: #fff;
          box-shadow: 0px 1px 0px 0px rgba(208, 215, 222, 0.2) inset;
        }
      }
    }

    .wrap {
      position: relative;
    }

    .actions-toggle {
      border-radius: 6px;
      border: 1px solid rgba(109, 181, 52, 0.24);
      background: rgba(109, 181, 52, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
    }

    .actions-popup {
      bottom: unset;
      top: 32px;
      right: 0;
      position: absolute;
      top: 100%;
      margin-top: 8px;
      background: #ffffff;
      border: 1px solid rgba(109, 181, 52, 0.24);
      border-radius: 6px;
      padding: 8px 0;
      min-width: 220px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;

      .dropdown-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 16px;
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 14px;
        color: #333;
        text-align: left;

        svg {
          margin-right: 8px;
        }

        &:hover {
          background: rgba(109, 181, 52, 0.12);
        }
      }
    }
  }
  :deep {
    .header {
      display: none;
    }
  }
</style>
