<template>
  <div class="popup small">
    <h4 class="popup__title middle">Удаление</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить данную запись?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="removeFunc('cancel')">Отмена</button>
      <button class="button btn-primary" @click="removeFunc('save')">Удалить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "removeReferencesPopup",
  mixins: [popups],
  data: () => ({}),
  computed: {
    ...mapState({
      isSaveRemoveReferencePopup: state => state.popups.isSaveRemoveReferencePopup,
    })
  },
  methods: {
    ...mapActions({
      removeCustomer: 'removeCustomer',
    }),
    ...mapMutations({
      setIsSaveRemoveReferencePopup: 'SET_IS_SAVE_REMOVE_REFERENCE_POPUP',
    }),
    async removeFunc(type) {
      let flag = 0;
      if (type === 'cancel') {
        flag = 1;
      }
      if (type === 'save') {
        flag = 2;
      }
      this.setIsSaveRemoveReferencePopup(flag)
      this.$forceUpdate();
      this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
</style>
