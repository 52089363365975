<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle
          class="path"
          cx="25"
          cy="25.2"
          r="19.9"
          fill="none"
          stroke-width="6"
          stroke-miterlimit="10"
        />
      </svg>
    </span>
    <h4 v-if="showAdminNotificationSettings" class="popup__title">Блокировка уведомлений</h4>
    <h4 v-else class="popup__title">Настройки уведомлений</h4>

    <div v-if="showAdminNotificationSettings" class="form">
      <div class="form__item">
        <div class="input">
          <label class="checkbox-cont" v-for="type in adminNotificationTypes" :key="type.id">
            <input v-model="type.value" :value="type.id" class="checkbox" type="checkbox" />
            <span class="indicator"></span>
            <span class="text">{{ type.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div v-else class="form">
      <div class="form__item">
        <div class="input">
          <label
            class="checkbox-cont"
            v-for="type in notificationTypes"
            :class="{ disabled: type.disabled }"
            :key="type.id"
          >
            <input
              :disabled="type.disabled"
              v-model="type.value"
              :value="type.id"
              class="checkbox"
              type="checkbox"
            />
            <span class="indicator"></span>
            <span class="text">{{ type.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="saveSettings()">Сохранить</button>
    </div>
  </div>
</template>

<script>
  import { popups } from '@/mixins/popups'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'notificationSettingsPopup',
    mixins: [popups],
    data: () => ({
      loading: false,
      notificationTypes: {
        blocked_card: { id: 1, name: 'Блокировка карты', value: false, disabled: true },
        balance_negative: { id: 2, name: 'Отрицательный баланс', value: false, disabled: true },
        credit_limit_exceeded: {
          id: 3,
          name: 'Превышение кредитного лимита',
          value: false,
          disabled: true
        }
      },
      adminNotificationTypes: {
        deny_edit_blocked_card: {
          id: 1,
          name: 'Запрет на редактирование блокировки карты',
          value: false
        },
        deny_edit_balance_negative: {
          id: 2,
          name: 'Запрет на редактирование отрицательного баланса',
          value: false
        },
        deny_edit_credit_limit_exceeded: {
          id: 3,
          name: 'Запрет на редактирование превышения кредитного лимита',
          value: false
        }
      }
    }),
    computed: {
      ...mapState({
        userNotificationSettings: (state) => state.global.userNotificationSettings,
        adminNotificationSettings: (state) => state.global.adminNotificationSettings,
        userRole: (state) => state.user.userRole,
        showAdminNotificationSettings: (state) => state.popups.showAdminNotificationSettingsPopup
      }),
      isAdmin() {
        return this.userRole === 1
      }
    },
    methods: {
      ...mapActions({
        setUserNotificationSettings: 'setUserNotificationSettings',
        getUserNotificationSettings: 'getUserNotificationSettings',
        getAdminNotificationSettings: 'getAdminNotificationSettings',
        setAdminNotificationSettings: 'setAdminNotificationSettings'
      }),
      async saveSettings() {
        this.loading = true
        try {
          if (this.showAdminNotificationSettings) {
            await this.setAdminNotificationSettings({
              deny_edit_blocked_card: this.adminNotificationTypes.deny_edit_blocked_card.value,
              deny_edit_balance_negative:
                this.adminNotificationTypes.deny_edit_balance_negative.value,
              deny_edit_credit_limit_exceeded:
                this.adminNotificationTypes.deny_edit_credit_limit_exceeded.value
            })
          } else {
            await this.setUserNotificationSettings({
              blocked_card: this.notificationTypes.blocked_card.value,
              balance_negative: this.notificationTypes.balance_negative.value,
              credit_limit_exceeded: this.notificationTypes.credit_limit_exceeded.value
            })
          }
          this.closeAll()
        } catch (error) {
          console.error(error)
        }
        this.loading = false
      }
    },
    async mounted() {
      if (!this.showAdminNotificationSettings) {
        await this.getUserNotificationSettings()
        Object.keys(this.userNotificationSettings).forEach((type) => {
          console.log(type)
          if (this.notificationTypes[type]) {
            this.notificationTypes[type].value = this.userNotificationSettings[type]
          }
        })
      }
      await this.getAdminNotificationSettings()
      Object.keys(this.adminNotificationSettings).forEach((type) => {
        console.log(type)
        if (this.adminNotificationTypes[type]) {
          this.adminNotificationTypes[type].value = this.adminNotificationSettings[type]
        }
      })
      if (!this.showAdminNotificationSettings) {
        this.notificationTypes.blocked_card.disabled =
          this.adminNotificationTypes.deny_edit_blocked_card.value
        this.notificationTypes.balance_negative.disabled =
          this.adminNotificationTypes.deny_edit_balance_negative.value
        this.notificationTypes.credit_limit_exceeded.disabled =
          this.adminNotificationTypes.deny_edit_credit_limit_exceeded.value
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

  .checkbox-cont {
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    gap: 10px;

    color: #1a1a18;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .form__item .input {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .input {
    .checkbox-cont {
      user-select: none;
      cursor: pointer;
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
  .text {
    text-align: left;
  }
</style>
