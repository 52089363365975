<template>
  <div class="page-header">
    <div class="title">
      <img :src="iconSrc" :alt="title + '-icon'" />
      <span class="text">{{ title }}</span>
    </div>
    <HeaderActions :show-menu-button="false" class="header-actions-desktop" />
  </div>
</template>

<script>
  import HeaderActions from './HeaderActions.vue'

  export default {
    name: 'PageHeader',
    components: {
      HeaderActions
    },
    props: {
      icon: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    },
    computed: {
      iconSrc() {
        return require(`@/assets/menu-icons/${this.icon}`)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../styles/variables';

  .page-header {
    display: flex;
    padding: 20px 28px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(26, 26, 24, 0.1);

    .title {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        width: 24px;
        height: 24px;
      }

      .text {
        color: $black;
        font-family: SF_Pro_SemiBold, Arial, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .header-actions-desktop {
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
</style>
