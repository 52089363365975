<template>
  <div class="form-wrapper">
    <div class="form">
      <div class="left">
        <div class="form__item">
          <div class="label">
            Имя или название
            организации *
          </div>
          <div class="input">
            <input type="text" v-model="client.employee">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Номер телефона
            для связи
          </div>
          <div class="input">
            <input type="text" v-model="client.phone">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Ф.И.О. *
          </div>
          <div class="input">
            <input type="text" v-model="client.name">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            ИНН
          </div>
          <div class="input">
            <input type="text" v-model="client.inn"><!--kpp ??? -->
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            КПП
          </div>
          <div class="input">
            <input type="text" v-model="client.kpp"><!--kpp ??? -->
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            ОГРН
          </div>
          <div class="input">
            <input type="text" v-model="client.ogrn">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Юридический
            адрес
          </div>
          <div class="input">
            <input type="text" v-model="client.address">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Расчетный счет
          </div>
          <div class="input">
            <input type="text" v-model="client.account_number">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Название банка
          </div>
          <div class="input">
            <input type="text" v-model="client.bank_name">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            БИК
          </div>
          <div class="input">
            <input type="text" v-model="client.bik">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Корреспондентский
            счет
          </div>
          <div class="input">
            <input type="text" v-model="client.correspondent_account">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            ОКПО
          </div>
          <div class="input">
            <input type="text" v-model="client.okpo">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            ФИО директора
          </div>
          <div class="input">
            <input type="text" v-model="client.ceo_name">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Номер телефона
            директора
          </div>
          <div class="input">
            <input type="text" v-model="client.ceo_phone">
          </div>
        </div>
      </div>
      <div class="right">
        <div class="form__item">
          <div class="label">
            Email *
          </div>
          <div class="input">
            <input
                :class="{'invalid': !emailValid}"
                type="email"
                v-model="client.login"
                @focus="emailValid = true"
                @update:model-value="validateEmail(client.login)"
                @blur="validateEmail(client.login)"
            >
          </div>
        </div>
        <!--        <div class="form__item">
                  <div class="label">
                    Статус
                  </div>
                  <div class="input">
                    <v-select :class="{'green': client.status?.id === 1, 'red': client.status?.id === 2}"
                              :options="user_statuses"
                              label="name"
                              v-model="client.status"
                              :clearable="false"
                              :searchable="false"
                    ></v-select>
                  </div>
                </div>-->
        <div class="form__item">
          <div class="label">
            Баланс
          </div>
          <div class="input">
            <input type="text" v-model="client.balance">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Корректировка баланса
          </div>
          <div class="input">
            <input type="text" v-model="client.correction_balance">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Менеджер *
          </div>
          <div class="input">
            <!--            <input type="text" v-model="client.manager_id">-->
            <v-select :options="managerOptions"
                      label="name"
                      v-model="client.manager"
                      @search="fetchOptions"
            >
              <template #no-options>
                Введите имя пользователя
              </template>
            </v-select>
          </div>
        </div>
        <!--      <div class="form__item">-->
        <!--        <div class="label">-->
        <!--          Разница скидки-->
        <!--        </div>-->
        <!--        <div class="input">-->
        <!--          <input type="text" v-model="client.discount">-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="form__item">
          <div class="label">
            Поручитель
          </div>
          <div class="input">
            <input type="text" v-model="client.surety">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Дата
            поручительства
          </div>
          <div class="input">
            <VueDatePicker
                v-model="client.surety_at"
                :enable-time-picker="false"
                format="dd.MM.yyyy"
                locale="ru"
                cancelText="Отмена"
                selectText="Выбрать"
                @update:model-value="formatingDate('surety')"
            />
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Кредитный
            лимит
          </div>
          <div class="input">
            <input type="text" v-model="client.limit">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Отсрочка
          </div>
          <div class="input">
            <input type="text" v-model="client.delay">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Устав
          </div>
          <div class="input">
            <input @click="uploadFile('charter')" type="text" v-model="getCharterFileValue">
            <button v-if="client.charterFiles && client.charterFiles.filename && !client.charter_file_name" @click="getFile('charter')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Свидетельство о постановке на учет
          </div>
          <div class="input">
            <input @click="uploadFile('certificate')" type="text" v-model="getCertificateFileValue">
            <button v-if="client.certificateOfRegistrationFile && client.certificateOfRegistrationFile.filename && !client.certificate_of_registration_file_name" @click="getFile('certificate')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Выписка из ЕГРЮЛ
          </div>
          <div class="input">
            <input @click="uploadFile('egryul')" type="text" v-model="getEgryulFileValue">
            <button v-if="client.extractFromEgryulFile && client.extractFromEgryulFile.filename && !client.extract_from_egryul_file_name" @click="getFile('egryul')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Приказ/решение о назначении директора
          </div>
          <div class="input">
            <input @click="uploadFile('director')" type="text" v-model="getDirectorFileValue">
            <button v-if="client.directorOrderFile && client.directorOrderFile.filename && !client.director_order_file_name" @click="getFile('director')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="form__item" v-if="edit">
          <div class="label">
            Статус
          </div>
          <div class="input">
            <v-select :class="{'green': client.status_id === 1, 'red': client.status_id === 2}"
                      :options="user_statuses"
                      label="name"
                      :reduce="option => option.id"
                      v-model="client.status_id"
                      :clearable="false"
                      :searchable="false"
            ></v-select>
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Интеграция *
          </div>
          <div class="input">
            <!--            <input type="text" v-model="client.manager_id">-->
            <v-select :options="integrations"
                      label="name"
                      v-model="client.integration_id"
                      :reduce="option => option.id"
                      :clearable="false"
                      :searchable="false"
            >
            </v-select>
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Интеграция платежей *
          </div>
          <div class="input">
            <!--            <input type="text" v-model="client.manager_id">-->
            <v-select :options="paymentIntegrations"
                      label="name"
                      v-model="client.payment_integration_id"
                      :reduce="option => option.id"
                      :clearable="false"
                      :searchable="false"
            >
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <input id="fileUpload" @input="addFile($event)" type="file" hidden>

    <div class="form__item surety">
      <div class="label">
        Договор на поставку
      </div>
      <div class="input">
      </div>
    </div>

    <div class="form__inputs">
      <div class="form__item">
        <div class="label">
          Номер договора
        </div>
        <div class="input">
          <input type="text" v-model="client.contract_number">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Дата поставки
        </div>
        <div class="input">
          <VueDatePicker
            v-model="client.contract_date"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            locale="ru"
            cancelText="Отмена"
            selectText="Выбрать"
            @update:model-value="formatingDate('supply')"
          />
        </div>
      </div>
    </div>

    <div class="form__item surety">
      <div class="label">
        Файлы договора
      </div>
      <div class="input">
        <button class="upload-surety" @click="uploadFile('supply')">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.75 6L9 2.25L5.25 6" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 2.25V11.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Загрузить файл
        </button>
      </div>
    </div>
    <template v-for="(file, i) in client.supplyContractFiles" :key="file.id">
      <div v-if="fileSupplyNotRemoved(file)" class="form__item surety-file-item">
        <div class="label">
          Файл
        </div>
        <div class="input">
          <input class="file-input" v-model="file.filename" type="text" disabled>
          <button class="additional-button" v-if="file" @click="getFile('contract', i)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button @click="removeSupplyFile(file)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.14269 5.83333V4C7.14269 3.60218 7.2932 3.22064 7.56111 2.93934C7.82902 2.65804 8.19238 2.5 8.57127 2.5H11.4284C11.8073 2.5 12.1707 2.65804 12.4386 2.93934C12.7065 3.22064 12.857 3.60218 12.857 4V5.83333M15.8332 5.83333L14.9998 16C14.9998 16.3978 14.8493 16.7794 14.5814 17.0607C14.3135 17.342 13.9501 17.5 13.5713 17.5H6.42841C6.04953 17.5 5.68617 17.342 5.41826 17.0607C5.15035 16.7794 4.99984 16.3978 4.99984 16L4.1665 5.83333H15.8332Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.3335 5.8335H4.81498H16.6668" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </template>
    <template v-for="(file, i) in supply_files_to_add" :key="file.id">
      <div class="form__item surety-file-item">
        <div class="label">
          Файл
        </div>
        <div class="input">
          <input class="file-input" v-model="file.file.name" type="text" disabled>
          <button @click="removeSupplyFileLocal(file)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.14269 5.83333V4C7.14269 3.60218 7.2932 3.22064 7.56111 2.93934C7.82902 2.65804 8.19238 2.5 8.57127 2.5H11.4284C11.8073 2.5 12.1707 2.65804 12.4386 2.93934C12.7065 3.22064 12.857 3.60218 12.857 4V5.83333M15.8332 5.83333L14.9998 16C14.9998 16.3978 14.8493 16.7794 14.5814 17.0607C14.3135 17.342 13.9501 17.5 13.5713 17.5H6.42841C6.04953 17.5 5.68617 17.342 5.41826 17.0607C5.15035 16.7794 4.99984 16.3978 4.99984 16L4.1665 5.83333H15.8332Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.3335 5.8335H4.81498H16.6668" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </template>


    <div class="form__item surety" style="border-top: 1px solid #F1F8EB;">
      <div class="label">
        Файлы поручительства
      </div>
      <div class="input">
        <button class="upload-surety" @click="uploadFile('surety')">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.75 6L9 2.25L5.25 6" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 2.25V11.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Загрузить файл
        </button>
      </div>
    </div>
    <template v-for="(file, i) in client.suretyFile" :key="file.id">
      <div v-if="fileNotRemoved(file)" class="form__item surety-file-item">
        <div class="label">
          Файл
        </div>
        <div class="input">
          <input class="file-input" v-model="file.filename" type="text" disabled>
          <button class="additional-button" v-if="file" @click="getFile('surety', i)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button @click="removeSuretyFile(file)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.14269 5.83333V4C7.14269 3.60218 7.2932 3.22064 7.56111 2.93934C7.82902 2.65804 8.19238 2.5 8.57127 2.5H11.4284C11.8073 2.5 12.1707 2.65804 12.4386 2.93934C12.7065 3.22064 12.857 3.60218 12.857 4V5.83333M15.8332 5.83333L14.9998 16C14.9998 16.3978 14.8493 16.7794 14.5814 17.0607C14.3135 17.342 13.9501 17.5 13.5713 17.5H6.42841C6.04953 17.5 5.68617 17.342 5.41826 17.0607C5.15035 16.7794 4.99984 16.3978 4.99984 16L4.1665 5.83333H15.8332Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.3335 5.8335H4.81498H16.6668" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </template>
    <template v-for="(file, i) in surety_files_to_add" :key="file.id">
      <div class="form__item surety-file-item">
        <div class="label">
          Файл
        </div>
        <div class="input">
          <input class="file-input" v-model="file.file.name" type="text" disabled>
          <button @click="removeFile(file)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.14269 5.83333V4C7.14269 3.60218 7.2932 3.22064 7.56111 2.93934C7.82902 2.65804 8.19238 2.5 8.57127 2.5H11.4284C11.8073 2.5 12.1707 2.65804 12.4386 2.93934C12.7065 3.22064 12.857 3.60218 12.857 4V5.83333M15.8332 5.83333L14.9998 16C14.9998 16.3978 14.8493 16.7794 14.5814 17.0607C14.3135 17.342 13.9501 17.5 13.5713 17.5H6.42841C6.04953 17.5 5.68617 17.342 5.41826 17.0607C5.15035 16.7794 4.99984 16.3978 4.99984 16L4.1665 5.83333H15.8332Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.3335 5.8335H4.81498H16.6668" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";
import {validation} from '@/mixins/validation'
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "clientForm",
  props: {
    client: {
      type: Object,
      default: {}
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validation],
  components: {vSelect, VueDatePicker},
  data: () => ({
    emailValid: true,
    fileType: ''
  }),
  computed: {
    ...mapState({
      managers: state => state.admin.managers,
      user_statuses: state => state.user.user_statuses,
      surety_files_to_remove: state => state.admin.surety_files_to_remove,
      surety_files_to_add: state => state.admin.surety_files_to_add,
      supply_files_to_remove: state => state.admin.supply_files_to_remove,
      supply_files_to_add: state => state.admin.supply_files_to_add,
      integrations: state => state.admin.integrations,
      paymentIntegrations: state => state.admin.paymentIntegrations,
    }),
    managerOptions() {
      let newArr = [];
      if (this.managers && this.managers.length > 0) {
        this.managers.forEach(item => {
          newArr.push({
            id: item.manager_id,
            name: item.name
          })
        })
      }
      return newArr
    },
    getSuretyFileValue() {
      let val = '';
      if (this.client.surety_file_name) {
        val = this.client.surety_file_name
        return val;
      }
      if (this.client.suretyFile && this.client.suretyFile.filename) {
        val = this.client.suretyFile.filename
      }
      return val;
    },
    getCharterFileValue() {
      let val = '';
      if (this.client.charter_file_name) {
        val = this.client.charter_file_name
        return val;
      }
      if (this.client.charterFiles && this.client.charterFiles.filename) {
        val = this.client.charterFiles.filename
      }
      return val;
    },
    getCertificateFileValue() {
      let val = '';
      if (this.client.certificate_of_registration_file_name) {
        val = this.client.certificate_of_registration_file_name
        return val;
      }
      if (this.client.certificateOfRegistrationFile && this.client.certificateOfRegistrationFile.filename) {
        val = this.client.certificateOfRegistrationFile.filename
      }
      return val;
    },
    getEgryulFileValue() {
      let val = '';
      if (this.client.extract_from_egryul_file_name) {
        val = this.client.extract_from_egryul_file_name
        return val;
      }
      if (this.client.extractFromEgryulFile && this.client.extractFromEgryulFile.filename) {
        val = this.client.extractFromEgryulFile.filename
      }
      return val;
    },
    getDirectorFileValue() {
      let val = '';
      if (this.client.director_order_file_name) {
        val = this.client.director_order_file_name
        return val;
      }
      if (this.client.directorOrderFile && this.client.directorOrderFile.filename) {
        val = this.client.directorOrderFile.filename
      }
      return val;
    },
  },
  methods: {
    ...mapMutations({
      setSuretyFilesToRemove: 'SET_SURETY_FILES_TO_REMOVE',
      setSupplyFilesToRemove: 'SET_SUPPLY_FILES_TO_REMOVE',
      pushToSuretyFilesToAdd: 'PUSH_TO_SURETY_FILES_TO_ADD',
      pushToSupplyFilesToAdd: 'PUSH_TO_SUPPLY_FILES_TO_ADD',
      setSuretyFilesToAdd: 'SET_SURETY_FILES_TO_ADD',
      setSupplyFilesToAdd: 'SET_SUPPLY_FILES_TO_ADD',
      removeSuretyFileFromListToAdd: 'REMOVE_SURETY_FILE_FROM_LIST_TO_ADD',
      removeSupplyFileFromListToAdd: 'REMOVE_SUPPLY_FILE_FROM_LIST_TO_ADD',
    }),
    ...mapActions({
      getAllUsers: 'getAllUsers',
      createUser: 'createUser',
      getUsersSearch: 'getUsersSearch',
      getFileByType: 'getFileByType',
    }),
    uploadXSLX() {
      document.getElementById("fileUpload").click()
    },
    uploadFile(type) {
      this.fileType = type;
      document.getElementById("fileUpload").click()
    },
    async addFile(file) {
      console.log(file)
      console.log(file.target.files[0])

      if (this.fileType === 'surety') {
        // this.client.surety_file = file.target.files[0]
        // this.client.surety_file_name = this.client.surety_file.name
        let dateNow = new Date();
        let timestamp = dateNow.getTime()
        let fileData = {
          file: file.target.files[0],
          id: timestamp
        }
        this.addSuretyFile(fileData)
      }
      if (this.fileType === 'supply') {
        // this.client.surety_file = file.target.files[0]
        // this.client.surety_file_name = this.client.surety_file.name
        let dateNow = new Date();
        let timestamp = dateNow.getTime()
        let fileData = {
          file: file.target.files[0],
          id: timestamp
        }
        this.addSupplyFile(fileData)
      }
      if (this.fileType === 'charter') {
        this.client.charter_file = file.target.files[0]
        this.client.charter_file_name = this.client.charter_file.name
      }
      if (this.fileType === 'certificate') {
        this.client.certificate_of_registration_file = file.target.files[0]
        this.client.certificate_of_registration_file_name = this.client.certificate_of_registration_file.name
      }
      if (this.fileType === 'egryul') {
        this.client.extract_from_egryul_file = file.target.files[0]
        this.client.extract_from_egryul_file_name = this.client.extract_from_egryul_file.name
      }
      if (this.fileType === 'director') {
        this.client.director_order_file = file.target.files[0]
        this.client.director_order_file_name = this.client.director_order_file.name
      }
    },
    fileNotRemoved(file) {
      let fileFound = this.surety_files_to_remove.find(item => item.id === file.id)
      return !fileFound;
    },
    fileSupplyNotRemoved(file) {
      let fileFound = this.supply_files_to_remove.find(item => item.id === file.id)
      return !fileFound;
    },
    removeSuretyFile(file) {
      let arr = JSON.parse(JSON.stringify(this.surety_files_to_remove))
      arr.push(file)
      this.setSuretyFilesToRemove(arr)
    },
    removeSupplyFile(file) {
      let arr = JSON.parse(JSON.stringify(this.supply_files_to_remove))
      arr.push(file)
      this.setSupplyFilesToRemove(arr)
    },
    removeFile(file) {
      console.log(file)
      this.removeSuretyFileFromListToAdd(file)
    },
    removeSupplyFileLocal(file) {
      console.log(file)
      this.removeSupplyFileFromListToAdd(file)
    },
    addSuretyFile(file) {
      this.pushToSuretyFilesToAdd(file)
    },
    addSupplyFile(file) {
      this.pushToSupplyFilesToAdd(file)
    },
    async getFile(type, index) {
      let data = {};
      if (type === 'surety') {
        data = {
          file_type: this.client.suretyFile[index].file_type,
          id: this.client.suretyFile[index].id,
          fileName: this.client.suretyFile[index].filename
        }
      }
      if (type === 'charter') {
        data = {
          file_type: this.client.charterFiles.file_type,
          id: this.client.charterFiles.id,
          fileName: this.client.charterFiles.filename
        }
      }
      if (type === 'certificate') {
        data = {
          file_type: this.client.certificateOfRegistrationFile.file_type,
          id: this.client.certificateOfRegistrationFile.id,
          fileName: this.client.certificateOfRegistrationFile.filename
        }
      }
      if (type === 'egryul') {
        data = {
          file_type: this.client.extractFromEgryulFile.file_type,
          id: this.client.extractFromEgryulFile.id,
          fileName: this.client.extractFromEgryulFile.filename
        }
      }
      if (type === 'director') {
        data = {
          file_type: this.client.directorOrderFile.file_type,
          id: this.client.directorOrderFile.id,
          fileName: this.client.directorOrderFile.filename
        }
      }
      if (type === 'contract') {
        data = {
          file_type: this.client.supplyContractFiles[index].file_type,
          id: this.client.supplyContractFiles[index].id,
          fileName: this.client.supplyContractFiles[index].filename
        }
      }
      await this.getFileByType(data)
    },
    async fetchOptions(search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getUsersSearch({
          search,
          type: 2
        })
        loading(false)
      }
    },
    formatingDate(type) {
      if (type === 'surety') {
        this.client.surety_at_model = `${this.client.surety_at.getFullYear()}-${String(this.client.surety_at.getMonth() + 1).padStart(2, '0')}-${String(this.client.surety_at.getDate()).padStart(2, '0')}`;
      }
      if (type === 'supply') {
        this.client.contract_date_model = `${this.client.contract_date.getFullYear()}-${String(this.client.contract_date.getMonth() + 1).padStart(2, '0')}-${String(this.client.contract_date.getDate()).padStart(2, '0')}`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.form-wrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  .form {
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid $back;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 40px;
    .form {
      flex-direction: column;
    }
  }
}

.left, .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.form__inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2px;

  .form__item {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.form__item {

  .input {
    position: relative;

    button {
      position: absolute;
      top: 50%;
      right: 10px;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      transform: translateY(-50%);

      &.additional-button {
        right: 35px;
      }
    }
  }
  input {
    padding-right: 30px !important;
    text-overflow: ellipsis !important;

    &:disabled {
      background: none !important;
    }

    @media screen and (max-width: 600px) {
      &.file-input {
        padding-right: 60px !important;
      }
    }
  }
}

.upload-surety {
  font-family: SF_Pro_Medium, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: $green;

  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;

  svg {
    padding-right: 8px;
  }
}

.surety {
  min-height: 34px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center !important;

  .label {
    font-family: SF_Pro_Medium, Arial, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0px  !important;
    text-align: left !important;
    color: $black !important;
    width: 200px !important;
  }
}

.surety-file-item {
  margin-bottom: 4px;
  padding-left: 10px;
}
</style>
