<template>
  <ul class="tabs-block" :class="{ 'relative': tabsListShown}">
    <li v-for="tab in tabsNames" @click="goTo(tab)" :class="{ 'tabs-block__active': $route.name === tab.linkName }">{{ tab.name }}</li>
    <li @click="showTabsList($event)" class="tabs-list-toggle">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <teleport to="body">
        <div v-show="tabsListShown" :style="{ top: menuY + 'px', right: menuX + 'px' }"
             ref="tabsListPopup"
             @mouseenter="mouseOverPopup($event)"
             @mouseleave="mouseLeavePopup"
             v-click-outside="onClickOutside" class="tabs-list-popup" :class="{ 'visible': tabsListShown }">
          <span v-for="tab in tabsNames" @click="goTo(tab)" class="tabs-list-popup__item" :class="{ 'active': $route.name === tab.linkName }">{{ tab.name }}</span>
        </div>
      </teleport>
    </li>
  </ul>
</template>

<script>
export default {
  name: "tabs",
  props: {
    tabsNames: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      activeIndex: 0,
      menuX: 0,
      menuY: 0,
      popupOpacity: 0,
      tabsListShown: false,
      mouseOverPopupFlag: false
    }
  },
  methods: {
    goTo(tab) {
      this.$router.push({name: tab.linkName});
    },
    async showTabsList(event) {
      if (this.tabsListShown) return
      const buttonRect = event.target.getBoundingClientRect();
      console.log(buttonRect);

      await this.$nextTick()

      const menuWidth = this.$refs['tabsListPopup'].offsetWidth; // Замените на ширину вашего меню
      const menuHeight = this.$refs['tabsListPopup'].offsetHeight; // Замените на высоту вашего меню

      let menuLeft = 20//buttonRect.right - window.innerWidth - (menuWidth/2)// - 15//Math.min(buttonRect.right, window.innerWidth - menuWidth - 15);
      let menuTop = Math.min(buttonRect.bottom - 10, window.innerHeight - menuHeight - 15);

      this.menuX = menuLeft
      this.menuY = menuTop

      await this.$nextTick()

      setTimeout(() => {
        this.tabsListShown = true
      }, 100)
      //this.popupOpacity = 1
    },
    onClickOutside (event) {
      if (this.tabsListShown) {
        this.tabsListShown = false
        this.popupOpacity = 0
      }
    },
    mouseLeavePopup(event) {
      setTimeout(() => {
        this.mouseOverPopupFlag = false
      }, 100)

      this.onClickOutside(event)
    },
    mouseOverPopup(event) {
      this.mouseOverPopupFlag = true
    },
  },
  mounted() {
    this.$emit('firstTabActive', this.$route.name === this.tabsNames[0].linkName)
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.tabs-block {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  position: sticky;
  left: 0;

  li {
    display: flex;
    padding: 16px 20px;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    color: $grey_1;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.44px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      color: $green !important;
      &:not(.tabs-block__active) {
        border-bottom: 1px solid $green;
      }
    }
  }

  .tabs-list-toggle {
    display: none;
  }

  &__active {
    color: $green !important;
    border-radius: 6px 6px 0 0;
    background: #FFFFFF;
  }

  @media screen and (max-width: 720px) {
    display: flex;
    //width: fit-content;
    max-width: calc(100% - 40px);
    //max-width: 500px;
    margin: auto;
    height: 32px;
    align-items: flex-end;
    gap: 0px;
    flex-shrink: 0;
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 30px;
    //position: relative;
    left: 20px;

    //&.relative {
    // // position: relative;
    //}

    li {
      display: none;
      &.tabs-block__active {
        box-sizing: border-box;
        padding: 0 5px;
        display: flex;
        //width: 274px;
        width: calc(100% - 77px);
        //flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        //align-self: stretch;
        border-radius: 6px 0px 0px 6px;
        border: 1px solid rgba(109, 181, 52, 0.24);
        background: #FFF;

        color: #1A1A18 !important;
        text-align: center;
        //font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        letter-spacing: unset;
        height: 100%;
      }

      &:hover {
        &:not(.tabs-block__active) {
          border-bottom: unset;
        }
      }
    }

    .tabs-list-toggle {
      display: flex;
      width: 77px;
      height: 32px;
      box-sizing: border-box;
      padding: 4px 28px;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 0px 6px 6px 0px;
      border: 1px solid rgba(109, 181, 52, 0.24);
      background: rgba(109, 181, 52, 0.12);
      position: relative;
      z-index: 12;
      svg {
        pointer-events: none;
      }
    }
  }
}

.tabs-list-popup {
  top: -1px;
  right: 0;
  position: absolute;
  display: flex;
  //height: 136px;
  width: max-content;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--white, #FFF);
  box-sizing: border-box;
  z-index: 12;

  /* button */
  box-shadow: 0px 4px 6px -2px rgba(80, 139, 33, 0.36);
  //opacity: 0;

  &.visible {
    opacity: 1;
  }

  &__item {
    cursor: pointer;
    width: 100%;
    text-align: left;
    display: flex;
    height: 40px;
    padding: 10px 6px 10px 6px;
    //justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;

    color: #1A1A18 !important;
    font-feature-settings: 'liga' off, 'clig' off;
    //font-family: "SF Pro Display";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    box-sizing: border-box;

    &.active {
      background: #F1F8EB;
    }
  }
}
</style>
