<template>
  <div class="header-actions">
    <router-link :to="{ name: 'notifications' }" class="header-button">
      <img src="../assets/menu-icons/bell-green.svg" alt="bell" />
      <span v-if="myUser?.unred_notifications_count" class="notification-badge">
        {{ myUser.unred_notifications_count }}
      </span>
    </router-link>
    <template v-if="showMenuButton">
      <svg
        class="separator"
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="24"
        viewBox="0 0 2 24"
        fill="none"
      >
        <path opacity="0.2" d="M1 0V24" stroke="#6DB534" />
      </svg>
      <button @click="$emit('toggle-menu')" class="header-button">
        <img :src="buttonIcon" :alt="buttonAlt" />
      </button>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'HeaderActions',
    props: {
      buttonIcon: {
        type: String,
        required: false
      },
      buttonAlt: {
        type: String,
        default: 'menu'
      },
      showMenuButton: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState({
        myUser: (state) => state.user.myUser
      })
    }
  }
</script>

<style scoped lang="scss">
  @import '../styles/variables';

  .header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    .separator {
      padding: 0;
      margin: auto;
    }

    .header-button {
      display: flex;
      width: var(--40, 40px);
      height: var(--40, 40px);
      padding: var(--8, 8px);
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: var(--4, 4px);
      border: 1px solid rgba(109, 181, 52, 0.12);
      box-sizing: border-box;
      background: transparent;
      position: relative;

      .notification-badge {
        position: absolute;
        top: -14px;
        right: -10px;
        color: #fff;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: SF_Pro_SemiBold, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
        flex-shrink: 0;
        display: flex;
        width: 24px;
        height: 24px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 13px;
        background: var(--orange, #f97f20);
        box-sizing: border-box;
      }
    }
  }
</style>
