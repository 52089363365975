<template>
  <div class="popup small">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title middle">Удаление файла</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить файл "<span>{{file.filename}}</span>"?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="deleteFile()">Удалить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
export default {
  name: "removeFilePopup",
  mixins: [popups],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      file: state => state.popups.file,
    })
  },
  methods: {
    ...mapActions({
      deletePriceFile: 'deletePriceFile',
      deletePriceGoldFile: 'deletePriceGoldFile',
      deletePricePartnerFile: 'deletePricePartnerFile',
      deleteTxFile: 'deleteTxFile',
    }),
    async deleteFile() {
      this.loading = true
      console.log(this.file)

      if (this.file.actionType === 'uploadPricesFiles') {
        if (this.$route.name === 'files_upload.prices_gold') {
          await this.deletePriceGoldFile(this.file.id)
        }
        if (this.$route.name === 'files_upload.prices_partner') {
          await this.deletePricePartnerFile(this.file.id)
        }
        if (this.$route.name === 'files_upload.prices') {
          await this.deletePriceFile(this.file.id)
        }
      } else if (this.file.actionType === 'uploadTxFiles') {
        await this.deleteTxFile(this.file.id)
      }

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
</style>