<template>
  <div>
    <h1>{{ $route.name }}</h1>
  </div>
</template>

<script>
export default {
  name: "Card",
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

</style>
