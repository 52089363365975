<template>
  <div class="popup wide">
    <h4 class="popup__title middle">Редактирование карты</h4>

    <div class="form">
        <div class="form__item">
          <div class="label">
            Держатель
          </div>
          <div class="input">
            <input type="text" v-model="holder">
          </div>
        </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="save()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
export default {
  name: "editClientCard",
  mixins: [popups],
  data: () => ({
    id: 0,
    holder: ''
  }),
  computed: {
    ...mapState({
      dataEditCardPopup: state => state.popups.dataEditCardPopup,
    })
  },
  methods: {
    ...mapActions({
      editCard: 'editCard',
    }),
    save() {
      this.editCard({id: this.id, data: {holder: this.holder}})
    }
  },
  mounted() {
    this.id = this.dataEditCardPopup.id;
    this.holder = this.dataEditCardPopup.holder;
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.left, .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.wide {
  max-width: unset;
  width: 460px;
  max-height: 80%;

  .form {
    //flex-direction: row;
    //justify-content: space-between;
    width: 100%;
    //overflow-y: auto;
    //overflow-x: hidden;
    //gap: 6px;

    .form__item {
      .label {
        width: 83px;
      }
      input {
        width: calc(100% - 20px) !important;
      }
    }
  }
}
</style>
