import * as type from './types';
import actions from './actions';
import api from '../../../api';

const state = {
    myUser: null,
    token: localStorage.getItem('token') || '',
    userRole: 0,
    allUsers: [],
    roles: [],
    user_statuses: [],
    card_limits: [],
    fuelTypes: [],
    regions: [],
    fuelProviders: [],
    stations: [],
};

const mutations = {
    [type.GET_MY_USER](state, payload) {
        state.myUser = payload
    },
    [type.SET_TOKEN](state, payload) {
        state.token = payload;
        api.interceptors.request.use(
            config => {
                config.headers.Authorization = 'Bearer ' + state.token;
                return config;
                },
                error => {
                Promise.reject(error);
                },
            );
    },
    SET_TOKEN(state, payload) {
        state.token = payload;
        api.interceptors.request.use(
          config => {
              config.headers.Authorization = 'Bearer ' + state.token;
              return config;
          },
          error => {
              Promise.reject(error);
          },
        );
    },
    SET_USER_ROLE(state, payload) {
        state.userRole = payload;
    },
    SET_ROLES(state, payload) {
        state.roles = payload;
    },
    SET_USER_STATUSES(state, payload) {
        state.user_statuses = payload;
    },
    SET_CARD_LIMITS(state, payload) {
        state.card_limits = payload;
    },
    GET_ALL_USERS(state, payload) {
        state.allUsers = payload;
    },
    SET_FUEL_TYPES(state, payload) {
        state.fuelTypes = payload;
    },
    SET_REGIONS(state, payload) {
        state.regions = payload;
    },
    SET_FUEL_PROVIDERS(state, payload) {
        let fuelProviders = [ ...payload ];
        fuelProviders.forEach(item => {
            if (item.partner) {
                item.name += ' (партнер)'
            }
        })
        state.fuelProviders = fuelProviders;
    },
    SET_STATIONS(state, payload) {
        state.stations = payload;
    },
};

const getters = {
};

export default {
    state,
    mutations,
    actions,
    getters,
};
