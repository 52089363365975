<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Добавление карт</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Номер карты
        </div>
        <div class="scroll-block">
          <div v-for="(card, index) of newCards" class="input">
            <v-select :options="cardsSelect"
                      label="number"
                      v-model="newCards[index]"
                      :reduce="option => option.id"
                      :clearable="false"
                      :searchable="true"
                      @search="fetchOptions"
                      :appendToBody="true"
            >
              <template #no-options>
                Введите имя
              </template>
            </v-select>
            <button @click="removeCard(index)" :disabled="newCards.length === 1" :style="{ opacity: newCards.length === 1 ? '.5' : '1' }">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <button class="add-card-btn" @click="addNewCard()">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.25 9H2.75" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.5 2.25V15.7501" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Добавить карту
        </button>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="save()">Сохранить</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
import vSelect from "vue-select";

export default {
  name: "setCardsForClient",
  components: {vSelect},
  mixins: [popups],
  data: () => ({
    loading: false,
    newCards: [
      null
    ]
  }),
  computed: {
    ...mapState({
      cardsSelect: state => state.admin.cardsSelect,
      customer: state => state.popups.customer,
    }),
  },
  methods: {
    ...mapActions({
      getCardsSelect: 'getCardsSelect',
      bunchSetCardsCustomer: 'bunchSetCardsCustomer',
    }),
    addNewCard() {
      this.newCards.push(null);
    },
    removeCard(index) {
      this.newCards.splice(index, 1);
    },
    async save() {
      this.loading = true;
      let data = {
        cards_ids: this.newCards,
        customer_id: this.customer.id
      }
      this.bunchSetCardsCustomer(data).then(res => {
        this.loading = false;
        this.closeAll()
      }).catch(e => {
        this.loading = false;
        this.closeAll()
      });
    },
    async fetchOptions(search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getCardsSelect({empty_customer: true})
        loading(false)
      }
    },
  },
  async mounted() {
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.form__item {
  flex-direction: column;
}

.input {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;

  .v-select {
    width: 100%;
  }

  & > button {
    margin-left: 10px;
    border: none;
    background: none;
  }
}

.popup .form__item .label {
  margin: 0 0 5px;
}

.add-card-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #6DB534;
  font-size: 12px;
  border: 1px solid #6DB534;
  border-radius: 4px;
  background: none;
  transition: all ease .3s;

  svg {
    margin-right: 10px;

    path {
      transition: all ease .3s;
    }
  }

  &:hover {
    background: $green;
    color: #FFFFFF;

    svg path {
      stroke: #FFFFFF;
    }
  }
}

.scroll-block {
  width: 100%;
  max-height: 60vh;
  overflow: auto;
}
</style>