<template>
  <div class="popup small">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title middle">Сброс пароля пользователя</h4>

    <span class="popup__subtitle">
      Вы точно желаете сбросить пароль данного пользователя?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="resetPassword()">Сбросить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
export default {
  name: "resetUserPasswordPopup",
  mixins: [popups],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      user: state => state.popups.user,
    })
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers',
      resetUserPassword: 'resetUserPassword',
    }),
    async resetPassword() {
      this.loading = true

      await this.resetUserPassword({
        email: this.user.email
      })

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
</style>