<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle
          class="path"
          cx="25"
          cy="25.2"
          r="19.9"
          fill="none"
          stroke-width="6"
          stroke-miterlimit="10"
        />
      </svg>
    </span>
    <h4 class="popup__title middle">Дата блокировки изменения скидок/наценок</h4>
    <div v-if="date" class="form">
      <div class="form__item">
        <div class="label">Дата</div>
        <div class="input">
          <VueDatePicker
            v-model="date"
            :enable-time-picker="false"
            :format="'dd.MM.yyyy'"
            locale="ru"
            cancelText="Отмена"
            selectText="Выбрать"
            class="only-day-picker"
            :clearable="false"
          />
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="!date" class="button btn-primary" @click="saveDate()">Сохранить</button>
    </div>
  </div>
</template>

<script>
  import { popups } from '@/mixins/popups'
  import { mapActions, mapState } from 'vuex'
  import VueDatePicker from '@vuepic/vue-datepicker'

  export default {
    name: 'setDiscountBlockDay',
    components: { VueDatePicker },
    mixins: [popups],
    data: () => ({
      loading: false,
      date: null
    }),
    computed: {
      ...mapState({
        discountBlockDay: (state) => state.customer.discountBlockDay,
        showDiscountBlockDayPopup: (state) => state.popups.showDiscountBlockDayPopup
      })
    },
    methods: {
      ...mapActions({
        getDiscountBlockDay: 'getDiscountBlockDay',
        setDiscountBlockDay: 'setDiscountBlockDay'
      }),
      async saveDate() {
        this.loading = true
        let date = `${this.date.getFullYear()}-${String(this.date.getMonth() + 1).padStart(2, '0')}-${String(this.date.getDate()).padStart(2, '0')}`;
        await this.setDiscountBlockDay({
          value: date
        })
        this.loading = false
      }
    },
    async mounted() {
      this.loading = true
      setTimeout(() => {
        this.date = new Date(this.discountBlockDay)
      }, 100)
      this.loading = false
    }
  }
</script>

<style lang="scss">
  @import '../../styles/variables';

  .only-day-picker {
    .dp__calendar_header {
      display: none;
    }
  }
</style>
