<template>
  <span>
    <svg v-if="order === 'desc' || order === ''" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M3 12.75H7.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 9H9.75" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5 8.25L13.5 14.25" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.75 12L13.5 14.25L11.25 12" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 5.25H12" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="order === 'asc'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M3 12.75L12 12.75" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 9H9.75" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 5.25H7.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5 9.75L13.5 3.75" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.75 6L13.5 3.75L11.25 6" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
</template>

<script>
export default {
  name: "orderFilterIcon",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    order: {
      type: String,
      default: ''
    }
  },
  data: () => ({

  }),
  computed: {
    color() {
      return this.isActive ? '#6DB534' : '#868685'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";
</style>
