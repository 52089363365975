<template>
  <div class="popup small">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title middle">Удаление пользователя</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить данного пользователя?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="deleteUser()">Удалить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
export default {
  name: "removeUserPopup",
  mixins: [popups],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      user: state => state.popups.user,
    })
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers',
      removeUser: 'removeUser',
    }),
    async deleteUser() {
      this.loading = true
      console.log(this.user)
      await this.removeUser(this.user.ID || this.user.id)

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
</style>