<template>
  <div class="page-container">
    <PageHeader icon="references-active.svg" title="Справочники" />
    <div class="page-content column">
      <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event" />
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive }">
        <TableComponent
          :columns="columns"
          :rows="[]"
          :isLightFontAdditional="true"
          :actionType="'references'"
          :actionType_2="'fuelTypes'"
          @addNew="addNewObj"
        />
        <div class="references-item" v-for="(item, index) of newArr">
          <div class="form__item">
            <div class="input">
              <v-select
                :options="fuelTypes"
                label="name"
                :reduce="(option) => option.id"
                v-model="item.fuel_type_id"
                :clearable="false"
                :searchable="false"
                @input="$forceUpdate()"
              ></v-select>
            </div>
          </div>
          <div class="form__item">
            <div class="input">
              <input type="text" v-model="item.name" @input="$forceUpdate()" />
            </div>
          </div>
          <button
            class="references-item__btn"
            :disabled="item.fuel_type_id === '' || item.name === ''"
            :style="{ opacity: item.fuel_type_id === '' || item.name === '' ? '.3' : '1' }"
            @click="addNewFunc(item, index)"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 9.50045L7.21231 13.2128L15.1673 5.25781"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            class="references-item__btn references-item__btn-cancel"
            @click="removeNewObj(index)"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14L5 5"
                stroke="#FF0000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 5L5 14"
                stroke="#FF0000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="references-item" v-for="item of arrayObj">
          <div class="form__item">
            <div class="input">
              <v-select
                :options="fuelTypes"
                label="name"
                :reduce="(option) => option.id"
                v-model="item.fuel_type_id"
                :clearable="false"
                :searchable="false"
                :disabled="!item.edit"
                @option:selected="$forceUpdate()"
              ></v-select>
            </div>
          </div>
          <div class="form__item">
            <div class="input">
              <input
                type="text"
                v-model="item.name"
                :disabled="!item.edit"
                @input="$forceUpdate()"
              />
            </div>
          </div>
          <button
            v-if="item.edit"
            :disabled="item.name === ''"
            :style="{ opacity: item.name === '' ? '.3' : '1' }"
            class="references-item__btn"
            @click="handleSaveEdit(item)"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 9.50045L7.21231 13.2128L15.1673 5.25781"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            v-if="item.edit"
            class="references-item__btn references-item__btn-cancel"
            @click="handleCancelEdit(item)"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14L5 5"
                stroke="#FF0000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 5L5 14"
                stroke="#FF0000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button v-if="!item.edit" class="references-item__btn" @click="handleStartEdit(item)">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.25 11.75L3.875 15.125L7.25 14.75L14.8358 7.16421C15.6168 6.38317 15.6168 5.11683 14.8358 4.33579L14.6642 4.16421C13.8832 3.38316 12.6168 3.38316 11.8358 4.16421L4.25 11.75Z"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 5L14 8"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.25 15.5H16.25"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button v-if="!item.edit" class="references-item__btn" @click="removeFunc(item)">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.92857 5.75V4.1C6.92857 3.74196 7.06403 3.39858 7.30515 3.14541C7.54627 2.89223 7.87329 2.75 8.21429 2.75H10.7857C11.1267 2.75 11.4537 2.89223 11.6949 3.14541C11.936 3.39858 12.0714 3.74196 12.0714 4.1V5.75M14.75 5.75L14 14.9C14 15.258 13.8645 15.6014 13.6234 15.8546C13.3823 16.1078 13.0553 16.25 12.7143 16.25H6.28571C5.94472 16.25 5.6177 16.1078 5.37658 15.8546C5.13546 15.6014 5 15.258 5 14.9L4.25 5.75H14.75Z"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 5.75H4.83333H15.5"
                stroke="#6DB534"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <span v-if="arrayObj.length === 0" class="no-data">Нет данных</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import Tabs from '@/components/tabs.vue'
  import vSelect from 'vue-select'

  export default {
    name: 'FuelTypes',
    components: { vSelect, Tabs },
    data: () => ({
      firstTabActive: true,
      tabsNames: [
        { name: 'ВИНКи', linkName: 'references.vinks' },
        { name: 'ВИНКи партнеров', linkName: 'references.vinksPartner' },
        { name: 'Регионы', linkName: 'references.regions' },
        { name: 'Типы топлива', linkName: 'references.fuelTypes' },
        { name: 'ВИНКи партнерских АЗС', linkName: 'references.vinksPartnerAZS' },
        { name: 'Золотые АЗС', linkName: 'references.goldAZS' }
      ],
      referencesArr: [],
      newArr: [],
      removeItem: {},
      tableColumns_1: [
        {
          name: 'Тип топлива',
          field: 'id',
          filter_name: 'id',
          filter_value: '',
          order: '',
          type: 'fuelTypes'
        },
        {
          name: 'Название',
          field: 'name',
          filter_name: 'name',
          filter_value: '',
          order: ''
        },
        {
          name: '',
          field: 'actions'
        }
      ]
    }),
    computed: {
      ...mapState({
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        fuelTypes: (state) => state.user.fuelTypes,
        fuelTypesReferences: (state) => state.references.fuelTypesReferences,
        isSaveRemoveReferencePopup: (state) => state.popups.isSaveRemoveReferencePopup
      }),
      arrayObj() {
        let arr = JSON.parse(JSON.stringify(this.referencesArr)).reverse()
        if (this.columns && this.columns[0].filter_value) {
          const iter = (o) => {
            console.log(1)
            return o.fuel_type_id === Number(this.columns[0].filter_value)
          }
          arr = arr.filter((item) => iter(item))
        }
        if (this.columns && this.columns[0].order) {
          const sortFunc = (a, b, sortFlag, last = false) => {
            if (!last && a === b) {
              return 0
            }
            if (sortFlag === 'desc') {
              return a > b ? 1 : -1
            } else {
              return a < b ? 1 : -1
            }
          }
          arr = arr.sort((a, b) => {
            return sortFunc(a.fuel_type_name, b.fuel_type_name, this.columns[0].order)
          })
        }
        if (this.columns && this.columns[1].filter_value) {
          const iter = (o) => {
            return o.name.toUpperCase().includes(this.columns[1].filter_value?.toUpperCase())
          }
          arr = arr.filter((item) => iter(item))
        }
        if (this.columns && this.columns[1].order) {
          const sortFunc = (a, b, sortFlag) => {
            if (sortFlag === 'desc') {
              return a > b ? 1 : -1
            } else {
              return a < b ? 1 : -1
            }
          }
          arr = arr.sort((a, b) => {
            return sortFunc(a.name, b.name, this.columns[1].order)
          })
        }
        return arr
      }
    },
    watch: {
      fuelTypesReferences(val) {
        this.referencesArr = JSON.parse(JSON.stringify(this.fuelTypesReferences))
        this.referencesArr.forEach((item) => {
          this.fuelTypes.forEach((type) => {
            item['edit'] = false
            if (item.fuel_type_id === type.id) {
              item['fuel_type_name'] = type.name
            }
          })
        })
      },
      isSaveRemoveReferencePopup(val) {
        console.log('isSaveRemoveReferencePopup', this.isSaveRemoveReferencePopup)
        if (this.isSaveRemoveReferencePopup === 1) {
          this.removeItem = {}
        }
        if (this.isSaveRemoveReferencePopup === 2) {
          this.removeFuelTypesReferences(this.removeItem.id).then((res) => {
            this.removeItem = {}
          })
        }
        this.setIsSaveRemoveReferencePopup(0)
      }
    },
    methods: {
      ...mapActions({
        getFuelTypesReferences: 'getFuelTypesReferences',
        editFuelTypesReferences: 'editFuelTypesReferences',
        addFuelTypesReferences: 'addFuelTypesReferences',
        removeFuelTypesReferences: 'removeFuelTypesReferences',
        getFuelTypesReq: 'getFuelTypes'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        toggleOverlay: 'TOGGLE_OVERLAY',
        setShowRemoveReferencePopup: 'SET_SHOW_REMOVE_REFERENCE_POPUP',
        setIsSaveRemoveReferencePopup: 'SET_IS_SAVE_REMOVE_REFERENCE_POPUP'
      }),
      editFunc(item) {
        let data = {
          id: item.id,
          field: {
            fuel_type_id: item.fuel_type_id,
            name: item.name
          }
        }
        this.editFuelTypesReferences(data)
      },
      addNewObj() {
        this.newArr.push({ fuel_type_id: '', name: '' })
        this.$forceUpdate()
      },
      removeNewObj(index) {
        this.newArr.splice(index, 1)
        this.$forceUpdate()
      },
      addNewFunc(item, index) {
        this.removeNewObj(index)
        let data = {
          fuel_type_catalog_id: item.fuel_type_id,
          name: item.name
        }
        this.addFuelTypesReferences(data)
      },
      removeFunc(item) {
        this.removeItem = item
        this.toggleOverlay(true)
        this.setShowRemoveReferencePopup(true)
      },
      handleSaveEdit(item) {
        item.edit = !item.edit
        this.$forceUpdate()
        this.editFunc(item)
      },
      handleCancelEdit(item) {
        item.edit = !item.edit
        this.$forceUpdate()
      },
      handleStartEdit(item) {
        item.edit = !item.edit
        this.$forceUpdate()
      }
    },
    async mounted() {
      this.setColumns(this.tableColumns_1)
      await this.getFuelTypesReq()
      await this.getFuelTypesReferences()
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

  .no-data {
    display: block;
    margin: 10px auto;
  }

  .references-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;

    .form__item {
      display: flex;
      align-items: flex-start;
      gap: 2px;
      align-self: stretch;
      width: 100%;
      margin: 0 15px 10px 0;

      .label {
        display: flex;
        width: 120px;
        height: 100%;
        margin: auto;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        color: $grey-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        text-align: left;
      }

      .input {
        flex-grow: 1;

        input {
          &:not(.vs__search) {
            border-radius: 6px;
            border: 1px solid $back;
            display: flex;
            padding: 10px;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
            width: -webkit-fill-available;

            color: $black;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: SF_Pro_Medium, Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            @media screen and (max-width: 600px) {
              width: 100%;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    .references-item__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      min-width: 42px;
      height: 42px;
      margin: 0 0 10px 0;
      padding: 0;
      border: none;
      border-radius: 4px;
      background: none;
      transition: all ease 0.3s;

      &:hover {
        background: rgba(109, 181, 52, 0.12);
      }
    }

    .references-item__btn-cancel {
      &:hover {
        background: rgba(255, 0, 0, 0.12);
      }
    }
  }
</style>
