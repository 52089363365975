<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Выбор интеграции</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Тип интеграции
        </div>
        <div class="input">
          <v-select :options="integrationsArr"
                    label="name"
                    v-model="integration"
                    :reduce="option => option.id"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="save()">Сохранить</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
import vSelect from "vue-select";

export default {
  name: "uploadCardsFrom",
  components: {vSelect},
  mixins: [popups],
  data: () => ({
    loading: false,
    // integrations: [
    //   {
    //     id: 1,
    //     name: 'Delko SmartOil'
    //   },
    //   {
    //     id: 2,
    //     name: 'Delko Синергия'
    //   },
    // ],
    integration: null,
    integrationsArr: []
  }),
  computed: {
    ...mapState({
      card: state => state.popups.card,
      integrations: state => state.admin.integrations,
    }),
  },
  methods: {
    ...mapActions({
      getUsersSearch: 'getUsersSearch',
      getCardsFromDelko: 'getCardsFromDelko',
    }),
    async save() {
      this.loading = true;
      this.getCardsFromDelko(this.integration).then(res => {
        this.loading = false;
        this.closeAll()
      }).catch(e => {
        this.loading = false;
        this.closeAll()
      });
    },
    async fetchOptions(search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getUsersSearch({
          search,
          type: 3
        })
        loading(false)
      }
    },
  },
  async mounted() {
    this.integrations.forEach(item => {
      if (item.id <= 2) {
        this.integrationsArr.push(item)
      }
    })
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
</style>