<template>
  <div class="page-container">
    <PageHeader icon="upload-active.svg" title="Загрузка файлов" />
    <div class="page-content column">
      <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event" />
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive }">
        <TableComponent
          :columns="columns"
          :rows="tableRows_1"
          :title="'Прайсы Делко'"
          :uploadFiles="true"
          :actionType="'uploadPricesFiles'"
          :noData="noData"
          @uploadXSLX="uploadXSLX"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @regetData="regetData($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import Tabs from '@/components/tabs.vue'

  export default {
    name: 'PricesUpload',
    components: { Tabs },
    data: () => ({
      firstTabActive: true,
      tabsNames: [
        { name: 'Загрузка транзакций', linkName: 'files_upload.transactions' },
        { name: 'Загрузка прайсов Делко', linkName: 'files_upload.prices' },
        { name: 'Загрузка прайсов золотых АЗС', linkName: 'files_upload.prices_gold' },
        { name: 'Загрузка прайсов партнерских АЗС', linkName: 'files_upload.prices_partner' }
      ],
      tableColumns_1: [
        {
          name: 'Название файла',
          field: 'filename',
          filter_name: 'filename',
          filter_value: '',
          order: ''
        },
        {
          name: 'Дата загрузки',
          field: 'uploaded_at',
          filter_name: 'uploaded_at',
          filter_value: '',
          order: '',
          date_type: true
        },
        {
          name: '',
          field: 'actions'
        }
      ],
      tableRows_1: [
        {
          file_name: '7013420001865393',
          uploaded_at: '2023-10-24 11:06:57',
          actionType: 'uploadFiles'
        },
        {
          file_name: '7013420001865393',
          uploaded_at: '2023-10-24 11:06:57'
        }
      ],
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,
      noData: false,
      files: null
    }),
    watch: {
      async prices_files(val) {
        await this.setTablesData()
      }
    },
    computed: {
      ...mapState({
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        prices_files: (state) => state.admin.prices_files
      })
    },
    methods: {
      ...mapActions({
        //uploadTransactionsFile: 'uploadTransactionsFile',
        getPricesFiles: 'getPricesFiles'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
        showUploadPrices: 'SHOW_UPLOAD_PRICES',
        toggleOverlay: 'TOGGLE_OVERLAY'
      }),
      uploadXSLX() {
        //document.getElementById("fileUpload").click()
        console.log('uploadXSLX')
        this.toggleOverlay(true)
        this.showUploadPrices(true)
      },
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'perPage',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getPricesFiles()
      },
      async addFile(file) {
        // console.log(file)
        // console.log(file.target.files[0])
        //
        // let formData = new FormData();
        // formData.append('file', file.target.files[0])
        //
        // //await this.uploadTransactionsFile(formData)

        console.log(file)
        console.log(file.target.files)
        this.files = file.target.files

        let formData = new FormData()
        //formData.append('files', file.target.files[0])

        //let filesArr = []
        for (let key in this.files) {
          if (this.files.hasOwnProperty(key)) {
            console.log(this.files[key])
            //filesArr.push(this.files[key])
            formData.append('files[]', this.files[key])
          }
        }

        //formData.append("files[]", filesArr);

        await this.uploadFiles(formData)
      },
      async setTablesData() {
        this.totalCount = this.prices_files.total_count
        this.pageSize = this.prices_files.page_size
        this.pageCount = this.prices_files.page_count
        this.currentPage = this.prices_files.current_page
        let pricesFilesData = []

        for (let i = 0; i < this.prices_files.data.length; i++) {
          let priceFile = this.prices_files.data[i]
          if (priceFile) {
            priceFile.actionType = 'uploadPricesFiles'
            pricesFilesData.push(priceFile)
          }
        }

        this.tableRows_1 = pricesFilesData
        console.log(this.tableRows_1)

        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }

        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          this.setColumns(this.tableColumns_1)
        }

        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)

      this.resetPaginationFilters()
      await this.getPricesFiles().catch((err) => {
        this.loading = false

        this.setColumns(this.tableColumns_1)
        this.noData = true
      })
      //this.setColumns(this.tableColumns_1)

      // setTimeout(() => {
      //   this.setColumns(this.tableColumns_1)
      // }, 1800)
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

  .page-content {
    display: flex;

    &.column {
      flex-direction: column;
    }

    > .left {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      //padding-right: 30px;
      //max-width: 630px;
      //min-width: 630px;
      //flex-wrap: wrap;
      width: 75%;
      border-right: 1px solid rgba(26, 26, 24, 0.1);
      .cards {
        display: flex;
      }
      @media screen and (min-width: 1440px) {
        //width: 60%;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      padding-left: 30px;
      width: 50%;
      flex-shrink: 1;
    }
  }

  .tab-content {
    &.offset {
      margin-top: 30px;
    }
  }
</style>
