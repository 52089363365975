<template>
  <div class="popup wide">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title middle">Сообщение</h4>

    <div class="form">
      <div class="form__item">
        <div class="input">
          <input type="text" placeholder="Тема" v-model="subject">
        </div>
      </div>
      <div class="form__item">
        <div class="textarea">
          <textarea placeholder="Текст" v-model="content" rows="9"/>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary"
              @click="sendEmailsFunc()"
              :disabled="subject === '' || content === ''"
              :style="{ opacity: subject === '' || content === '' ? '.6' : '' }"
      >
        Отправить
      </button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
export default {
  name: "removeClientPopup",
  mixins: [popups],
  data: () => ({
    loading: false,
    subject: '',
    content: '',
  }),
  computed: {
    ...mapState({
      client: state => state.popups.client,
    })
  },
  methods: {
    ...mapActions({
      sendEmails: 'sendEmails',
    }),
    async sendEmailsFunc() {
      this.loading = true
      await this.sendEmails({subject: this.subject, content: this.content})

      this.loading = false
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.wide {
  max-width: unset;
  width: 560px;
  max-height: 80%;

  .form {
    //flex-direction: row;
    //justify-content: space-between;
    width: 100%;
    //overflow-y: auto;
    //overflow-x: hidden;
    //gap: 6px;

    .form__item {
      margin-bottom: 10px;
      .label {
        width: 83px;
      }
      input {
        width: calc(100% - 20px) !important;
      }
    }
  }
}
</style>