<template>
  <div class="popup small">
    <h4 class="popup__title middle">Удаление клиента</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить данного клиента?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="deleteCustomer()">Удалить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
export default {
  name: "removeClientPopup",
  mixins: [popups],
  data: () => ({}),
  computed: {
    ...mapState({
      client: state => state.popups.client,
    })
  },
  methods: {
    ...mapActions({
      removeCustomer: 'removeCustomer',
    }),
    async deleteCustomer() {
      this.loading = true
      await this.removeCustomer(this.client.ID || this.client.id)

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
</style>