<template>
  <div class="page-container">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle
          class="path"
          cx="25"
          cy="25.2"
          r="19.9"
          fill="none"
          stroke-width="6"
          stroke-miterlimit="10"
        />
      </svg>
    </span>
    <PageHeader icon="analytics-active.svg" title="Аналитика" />
    <div v-if="!loading" class="page-content">
      <div v-if="customers" class="filter">
        <div class="label">Фильтр по клиентам</div>
        <v-select
          :options="customers.data"
          label="employee"
          v-model="client"
          :reduce="(option) => option.id"
          :clearable="false"
          :searchable="false"
          class="filter-select"
          @option:selected="reGetData()"
          placeholder="Название клиента"
        ></v-select>
      </div>
      <div class="graph" v-if="analytics">
        <div class="header">
          <div class="title">График</div>
          <v-select
            :options="rangeOptions"
            label="name"
            v-model="range"
            :clearable="false"
            :searchable="false"
            class="range-select"
            @input="renderData"
          ></v-select>
        </div>

        <CanvasJSChart @chart-ref="chartRef" ref="chart" :options="options" :style="styleOptions" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  const months = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь'
  }

  export default {
    name: 'Analytics',
    data() {
      return {
        clientsArr: [],
        client: null,
        range: { id: 2, name: 'Год', value: 'last_year' },
        rangeOptions: [
          { id: 1, name: 'Месяц', value: 'last_month' },
          { id: 2, name: 'Год', value: 'last_year' }
        ],
        loading: false,
        chart: null,
        styleOptions: {
          width: '100%'
          //height: "360px"
        }
      }
    },
    computed: {
      ...mapState({
        customers: (state) => state.admin.customers,
        analytics: (state) => state.admin.analytics
      }),
      chartData() {
        if (!this.analytics || !this.analytics[this.range.value]) return []

        let data = []
        let today = new Date()
        let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
        let monthNum = today.getMonth() + 1
        if (this.range.value === 'last_year') {
          let arr = []
          let count = 0
          for (let i = 12; i > 0; i--) {
            let countMonth = 0
            let yearCount = 0
            if (monthNum - count <= 0) {
              countMonth = 12
              yearCount = 1
            }
            arr.push({
              x: i,
              y: 0,
              label: `${months[monthNum - count + countMonth]} ${today.getFullYear() - yearCount}`
            })
            this.analytics[this.range.value].forEach((item) => {
              let analyticsMonth = new Date(item.month).getMonth() + 1
              if (analyticsMonth === monthNum - count + countMonth) {
                arr[arr.length - 1].y = parseFloat(item.total_fuel)
              }
            })
            count++
          }
          data = arr
          // return this.analytics[this.range.value].map((item) => ({
          //   x: new Date(item.month),
          //   y: parseFloat(item.total_fuel),
          //   label: months[new Date(item.month).getMonth() + 1]
          // }))
        }

        if (this.range.value === 'last_month') {
          for (let i = 1; i <= lastDayOfMonth; i++) {
            data.push({
              x: i,
              y: 0,
              label: i
            })
          }
          this.analytics[this.range.value].forEach((item) => {
            let day = new Date(item.day).getDate()
            data[day - 1].y = parseFloat(item.total_fuel)
          })
          // return this.analytics[this.range.value].map((item) => ({
          //   x: new Date(item.month),
          //   y: parseFloat(item.total_fuel),
          //   label: months[new Date(item.month).getMonth() + 1]
          // }))
        }

        return data
      },
      options() {
        return {
          animationEnabled: true,
          theme: 'light2',
          axisX: {
            valueFormatString: ' ',
            labelFormatter: (e) => {
              // if (this.range.value === 'last_year') {
              //   return months[e.label]
              // } else {
              //   return e.value;
              // }
              return e.label
            },
            interval: 1,
            crosshair: {
              enabled: false
            }
          },
          axisY: {
            title: 'Объем топлива (л)',
            crosshair: {
              enabled: true
            },
            labelFormatter: function (e) {
              return e.value.toFixed(2) + ' л'
            }
          },
          toolTip: {
            shared: true,
            backgroundColor: '#6DB534',
            cornerRadius: 4,
            fontColor: '#ffffff',
            borderColor: '#6DB534',
            shadowColor: 'rgba(0, 0, 0, 0.10)',
            shadowDepth: 4,
            contentFormatter: function (e) {
              let content = `<strong>${e.entries[0].dataPoint.label}</strong><br/>`
              content += `Объем: ${e.entries[0].dataPoint.y.toFixed(2)} л`
              return content
            }
          },
          data: [
            {
              type: 'area',
              fillOpacity: 0.3,
              color: '#6DB534',
              name: 'Объем топлива',
              dataPoints: this.chartData
            }
          ]
        }
      }
    },
    methods: {
      ...mapActions({
        getCustomers: 'getCustomers',
        getAnalytics: 'getAnalytics'
      }),
      renderData() {
        if (this.$refs.chart && this.$refs.chart.chart) {
          this.$refs.chart.chart.render()
        }
      },
      async reGetData() {
        this.loading = true
        await this.getAnalytics({
          client: this.client
        })
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    watch: {
      //range: {
      analytics: {
        handler() {
          //this.reGetData();
          this.renderData()
        },
        deep: true
      }
    },
    async mounted() {
      this.loading = true
      await this.getCustomers()
        .then((res) => {
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
        })
    }
  }
</script>

<style scoped lang="scss">
  .analytics {
    padding: 24px;
  }

  .page-content {
    gap: 24px;
    padding-top: 24px;
  }

  .filter {
    display: flex;
    max-width: 486.5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin: 30px;

    .label {
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      color: var(--grey-1, #575656);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      /* 133.333% */
    }

    .v-select {
      width: 100%;
      border-radius: 6px;
      border: 1px solid rgba(109, 181, 52, 0.3);
      background: #fff;
    }

    @media screen and (max-width: 600px) {
      margin: 20px;
      margin-bottom: 30px;
    }
  }

  .graph {
    display: flex;
    width: 100%;
    //height: 334px;
    padding: 28px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 6px 36px 0px rgba(80, 139, 33, 0.24);
    box-sizing: border-box;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .title {
        color: #000;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-bottom: 28px;
      }

      .range-select {
        border-radius: 8px;
        background: var(--back, #f1f8eb);

        color: var(--green, #6db534);
        text-align: center;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        width: 95px;
        height: 33px;
      }
    }
  }
</style>
<style>
  .canvasjs-chart-credit {
    display: none;
  }
</style>
