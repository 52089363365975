<template>
  <div class="page-container">
    <PageHeader icon="reports-active.svg" title="Отчеты" />
    <tabs v-if="tabs" :tabsNames="tabs" @firstTabActive="firstTabActive = $event" />
    <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive }">
      <div class="content">
        <div class="wrap">
          <span class="title">Вы можете скачать зарплатный отчет</span>
          <button class="btn-primary export-btn" @click="download" :disabled="setDisabled">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 10L8 12M8 12L10 10M8 12L8 8"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="text">Скачать</span>
          </button>
        </div>

        <div class="actions">
          <div class="period salary-select" v-if="userRole === 1">
            <span class="date-picker-label">Менеджер</span>
            <v-select
              :options="managerOptions"
              label="name"
              v-model="manager"
              placeholder="Выберите менеджера"
              @search="fetchOptions"
              :clearable="false"
            >
              <template #no-options>Введите имя пользователя</template>
            </v-select>
          </div>
          <div class="period">
            <span class="date-picker-label">Выбрать период</span>
            <VueDatePicker
              input-class-name="month-picker"
              v-model="date"
              month-picker
              :enable-time-picker="false"
              :format="format"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              :clearable="false"
            >
              <!--model-type="MM.dd.yyyy"
            @update:model-value="setDate"
            :model-value="date"
              format="LLLL, yyyy"-->
              <template #input-icon>
                <img class="input-slot-image" src="../../assets/datepicker-icon.svg" alt="d" />
              </template>
            </VueDatePicker>
          </div>

          <button class="btn-primary export-btn" @click="download" :disabled="setDisabled">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 10L8 12M8 12L10 10M8 12L8 8"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="text">Скачать</span>
          </button>
        </div>
      </div>
      <!--      <TableComponent
          :title="'Вы можете скачать зарплатный отчет'"
          :show-additional-table-header="false"
          :noTable="true"
      />-->
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/tabs.vue'
  import { mapActions, mapState } from 'vuex'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import vSelect from 'vue-select'

  export default {
    name: 'SalaryReport',
    components: {
      VueDatePicker,
      Tabs,
      vSelect
    },
    data() {
      return {
        firstTabActive: false,
        tabsNames: [
          { name: 'Транзакционный отчет', linkName: 'reports.transactions' },
          { name: 'Платежный отчет', linkName: 'reports.payment' },
          { name: 'Отчет по оборотам карт', linkName: 'reports.cards' },
          { name: 'Зарплатный отчет', linkName: 'reports.salary' },
          { name: 'Отчет Сверка', linkName: 'reports.revise' },
          { name: 'Отчет по задолженностям', linkName: 'reports.debt' },
          { name: 'Акт сверки', linkName: 'reports.reconciliation' }
        ],
        date: null,
        manager: null
      }
    },
    computed: {
      ...mapState({
        token: (state) => state.user.token,
        userRole: (state) => state.user.userRole,
        managers: (state) => state.admin.managers
      }),
      tabs() {
        switch (this.userRole) {
          case 3:
            return [this.tabsNames[0], this.tabsNames[1]]
          case 2:
            return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
          case 1:
            return [
              this.tabsNames[0],
              this.tabsNames[4],
              this.tabsNames[3],
              this.tabsNames[2],
              this.tabsNames[5],
              this.tabsNames[6]
            ]
        }
      },
      managerOptions() {
        let newArr = []
        if (this.managers && this.managers.length > 0) {
          this.managers.forEach((item) => {
            newArr.push({
              id: item.manager_id,
              name: item.name
            })
          })
        }
        return newArr
      },
      setDisabled() {
        if (this.userRole === 2) {
          return false
        }
        if (this.userRole === 1) {
          return this.manager === null
        }
      }
    },
    methods: {
      ...mapActions({
        getSalaryReport: 'getSalaryReport',
        getUsersSearch: 'getUsersSearch'
      }),
      download() {
        let data = {
          period: {
            month: this.date.month + 1,
            year: this.date.year
          }
        }

        if (this.userRole === 1) {
          data.manager_id = this.manager.id
        }
        this.getSalaryReport(data)
      },
      async fetchOptions(search, loading) {
        if (search.length >= 3) {
          loading(true)

          await this.getUsersSearch({
            search,
            type: 2
          })
          loading(false)
        }
      }
    },
    mounted() {
      let date = new Date()
      let month = date.getMonth()
      let year = date.getFullYear()
      if (month === 0) {
        month = 11
        year = Number(year) - 1
      }
      this.date = {
        month,
        year
      }
    }
  }
</script>
<script setup>
  // In case of a range picker, you'll receive [Date, Date]
  import vSelect from 'vue-select'

  const format = (date) => {
    console.log('DATE IS: ', date)
    //const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();

    let options = { year: 'numeric', month: 'long' }
    console.log(date.toLocaleString('ru-RU', options))

    return `${date.toLocaleString('ru-RU', options)}`
      .replace('г.', '')
      .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
  }
</script>

<style lang="scss">
  @import '../../styles/variables';
  .salary-select {
    .vs__dropdown-toggle {
      height: 30px;
    }

    .vs__dropdown-menu {
      top: 30px;
    }
  }
</style>

<style scoped lang="scss">
  @import '../../styles/variables';
  .separator {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 1px;
    background: $back;
    width: 100%;
  }
  .content {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    justify-content: space-between;

    @media screen and (max-width: 720px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      color: $black;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: M_SemiBold, Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }

    .actions {
      display: flex;
      padding: 15px 10px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      justify-content: space-between;

      @media screen and (max-width: 720px) {
        padding-top: 0;
        flex-wrap: wrap;
        .export-btn {
          display: none;
        }
      }
    }

    button {
      height: 30px;
      flex-grow: 0;
    }

    .period {
      display: flex;
      align-items: center;
      gap: 2px;
      align-self: stretch;

      .date-picker-label {
        color: $grey-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        padding-right: 10px;
      }

      .dp__main {
        width: 150px !important;
        height: 30px !important;
      }

      .v-select {
        width: 200px !important;
        height: 30px !important;
      }

      //.dp__input_icon {
      //  top: 55%;
      //}

      @media screen and (max-width: 460px) {
        justify-content: space-between;
        width: 100%;
      }
    }

    .wrap {
      .export-btn {
        display: none;
      }

      @media screen and (max-width: 720px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        text-align: left;
        gap: 10px;
        .export-btn {
          display: flex;
        }
      }
    }
  }
</style>
