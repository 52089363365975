import * as type from './types'
import api from '../../../api'
import { getFinalUrl } from '@/utils/url_formatting'

const actions = {
  // getMyUser({commit}) {
  //   return new Promise((resolve, reject) => {
  //     api.get(`user/me`)
  //       .then(res => {
  //         commit(type.GET_MY_USER, res.data);
  //         resolve(res);
  //       })
  //       .catch(error => {
  //         console.log('Problem', error.message);
  //         reject(error);
  //       });
  //   })
  // },
  async getNotifications({ commit, dispatch, rootState }, getFile = false) {
    let url = `notification/get-all`
    let finalUrl = await getFinalUrl(url, rootState)
    return new Promise(async (resolve, reject) => {
      api
        .get(finalUrl)
        .then((res) => {
          commit(type.SET_NOTIFICATIONS, res.data)
          commit(type.SET_TOTAL_NOTIFICATIONS_COUNT, res.data.totalCount)
          resolve(res)
        })
        .catch((error) => {
          console.log('Problem', error.message)
          commit('TOGGLE_OVERLAY', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error)
        })
    })
  },
  async getUserNotificationSettings({ commit, dispatch, rootState }) {
    return new Promise(async (resolve, reject) => {
      api
        .get(`user-notification-settings/get`)
        .then((res) => {
          commit(type.SET_USER_NOTIFICATION_SETTINGS, res.data)
          resolve(res)
        })
        .catch((error) => {
          console.log('Problem', error.message)
          commit('TOGGLE_OVERLAY', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error)
        })
    })
  },
  async setUserNotificationSettings({ commit, dispatch, rootState }, data) {
    return new Promise(async (resolve, reject) => {
      api
        .put(`user-notification-settings/edit`, data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          console.log('Problem', error.message)
          commit('TOGGLE_OVERLAY', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error)
        })
    })
  },
  async readNotification({ commit, dispatch, rootState }, id) {
    return new Promise(async (resolve, reject) => {
      api
        .put(`notification/${id}/set-red`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          console.log('Problem', error.message)
          commit('TOGGLE_OVERLAY', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error)
        })
    })
  },
  async getAdminNotificationSettings({ commit, dispatch, rootState }) {
    return new Promise(async (resolve, reject) => {
      api
        .get(`user-notification-settings/get-notification-settings`)
        .then((res) => {
          commit(type.SET_ADMIN_NOTIFICATION_SETTINGS, res.data)
          resolve(res)
        })
        .catch((error) => {
          console.log('Problem', error.message)
          commit('TOGGLE_OVERLAY', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error)
        })
    })
  },
  async setAdminNotificationSettings({ commit, dispatch, rootState }, data) {
    return new Promise(async (resolve, reject) => {
      api
        .put(`user-notification-settings/set-notification-settings`, data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          console.log('Problem', error.message)
          commit('TOGGLE_OVERLAY', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error)
        })
    })
  }
}

export default actions
