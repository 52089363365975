import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/Pages/Login.vue'
import Dashboard from '@/Pages/Client/Dashboard.vue'
import TransactionsReport from '@/Pages/Reports/TransactionsReport.vue'
import ReviseReport from '@/Pages/Reports/ReviseReport.vue'
import PaymentsReport from '@/Pages/Reports/PaymentsReport.vue'
import CardsReport from '@/Pages/Reports/CardsReport.vue'
import Payments from '@/Pages/Payments.vue'
import ClientManagement from '@/Pages/ClientManagement.vue'
import CardsManagement from '@/Pages/Admin/CardsManagement.vue'
import UsersManagement from '@/Pages/Admin/UsersManagement.vue'
import SalaryReport from '@/Pages/Reports/SalaryReport.vue'
import ExecutiveDashboard from '@/Pages/Admin/ExecutiveDashboard.vue'
import AccountantDashboard from '@/Pages/Admin/AccountantDashboard.vue'
import OperativeDashboard from '@/Pages/Admin/OperativeDashboard.vue'
import TransactionsUpload from '@/Pages/Admin/TransactionsUpload.vue'
import PricesUpload from '@/Pages/Admin/PricesUpload.vue'
import Card from '@/Pages/Card.vue'
import NotFound from '@/Pages/404.vue'
import store from '@/store/index'
import AccountManagementRequisites from '@/Pages/Client/AccountManagementRequisites.vue'
import AccountManagementFuelCards from '@/Pages/Client/AccountManagementFuelCards.vue'
import Vinks from '@/Pages/References/Vinks.vue'
import VinksPartner from '@/Pages/References/VinksPartner.vue'
import Regions from '@/Pages/References/Regions.vue'
import FuelTypes from '@/Pages/References/FuelTypes.vue'
import GoldAZSupload from '@/Pages/Admin/GoldAZSupload.vue'
import PartnerAZSupload from '@/Pages/Admin/PartnerAZSupload.vue'
import Analytics from '@/Pages/Admin/Analytics.vue'
import DebtReport from '@/Pages/Reports/DebtReport.vue'
import vinksPartnerAZS from '@/Pages/References/VinksPartnerAZS.vue'
import NotificationsPage from '@/Pages/NotificationsPage.vue'
import ReconciliationReport from "@/Pages/Reports/ReconciliationReport.vue";
import GoldAZS from "@/Pages/References/GoldAZS.vue";

const routes = [
  {
    path: '/',
    name: 'main'
    // redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/payments',
    name: 'payments',
    component: Payments,
    meta: {
      title: 'Платежи'
    }
  },
  {
    path: '/client_management',
    name: 'client_management',
    component: ClientManagement,
    meta: {
      title: 'Управление клиентами'
    }
  },
  {
    path: '/cards_management',
    name: 'cards_management',
    component: CardsManagement,
    meta: {
      title: 'Управление картами'
    }
  },
  {
    path: '/users_management',
    name: 'users_management',
    component: UsersManagement,
    meta: {
      title: 'Управление пользователями'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Дашборд'
    }
  },
  {
    path: '/admin-dashboard',
    name: 'admin.dashboard',
    redirect: '/admin-dashboard/executive',
    children: [
      {
        path: '/admin-dashboard/executive',
        name: 'admin.dashboard.executive',
        component: ExecutiveDashboard,
        meta: {
          title: 'Дашборд - Дашборд руководителя'
        }
      },
      {
        path: '/admin-dashboard/accountant',
        name: 'admin.dashboard.accountant',
        component: AccountantDashboard,
        meta: {
          title: 'Дашборд - Дашборд бухгалтера'
        }
      },
      {
        path: '/admin-dashboard/operative',
        name: 'admin.dashboard.operative',
        component: OperativeDashboard,
        meta: {
          title: 'Дашборд - Оперативный отчет'
        }
      }
    ]
  },
  {
    path: '/files-upload',
    name: 'files_upload',
    redirect: '/files-upload/transactions',
    children: [
      {
        path: '/files-upload/transactions',
        name: 'files_upload.transactions',
        component: TransactionsUpload,
        meta: {
          title: 'Загрузка файлов - Загрузка транзакций'
        }
      },
      {
        path: '/files-upload/prices',
        name: 'files_upload.prices',
        component: PricesUpload,
        meta: {
          title: 'Загрузка файлов - Загрузка прайсов Делко'
        }
      },
      {
        path: '/files-upload/prices-gold',
        name: 'files_upload.prices_gold',
        component: GoldAZSupload,
        meta: {
          title: 'Загрузка файлов - Загрузка прайсов золотых АЗС'
        }
      },
      {
        path: '/files-upload/prices-partner',
        name: 'files_upload.prices_partner',
        component: PartnerAZSupload,
        meta: {
          title: 'Загрузка файлов - Загрузка прайсов партнерских АЗС'
        }
      }
    ]
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: '/reports/transactions',
    children: [
      {
        path: '/reports/transactions',
        name: 'reports.transactions',
        component: TransactionsReport,
        meta: {
          title: 'Отчеты - Транзакционный отчет'
        }
      },
      {
        path: '/reports/revise',
        name: 'reports.revise',
        component: ReviseReport,
        meta: {
          title: 'Отчеты - Отчет Сверка'
        }
      },
      {
        path: '/reports/payments',
        name: 'reports.payment',
        component: PaymentsReport,
        meta: {
          title: 'Отчеты - Платежный отчет'
        }
      },
      {
        path: '/reports/cards',
        name: 'reports.cards',
        component: CardsReport,
        meta: {
          title: 'Отчеты - Отчет по оборотам карт'
        }
      },
      {
        path: '/reports/salary',
        name: 'reports.salary',
        component: SalaryReport,
        meta: {
          title: 'Отчеты - Зарплатный отчет'
        }
      },
      {
        path: '/reports/debt',
        name: 'reports.debt',
        component: DebtReport,
        meta: {
          title: 'Отчеты - Отчет по задолженностям'
        }
      },
      {
        path: '/reports/reconciliation',
        name: 'reports.reconciliation',
        component: ReconciliationReport,
        meta: {
          title: 'Отчеты - Акт сверки'
        }
      }
    ]
  },
  {
    path: '/card',
    name: 'card',
    component: Card,
    meta: {
      title: 'Карта'
    }
  },
  // {
  //   path: '/account-management',
  //   name: 'accountManagement',
  //   redirect: '/account-management/requisites',
  //   children: [
  //     {
  //       path: '/account-management/requisites',
  //       name: 'accountManagement.requisites',
  //       component: AccountManagementRequisites,
  //       meta: {
  //         title: 'Управление аккаунтом - Реквизиты'
  //       }
  //     },
  //     {
  //       path: '/account-management/fuel-cards',
  //       name: 'accountManagement.fuelCards',
  //       component: AccountManagementFuelCards,
  //       meta: {
  //         title: 'Управление аккаунтом - Топливные карты'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/account-management',
    name: 'accountManagement',
    component: AccountManagementRequisites,
    meta: {
      title: 'Реквизиты'
    }
  },
  {
    path: '/fuel-cards',
    name: 'fuelCards',
    component: AccountManagementFuelCards,
    meta: {
      title: 'Топливные карты'
    }
  },
  {
    path: '/transactions-reports',
    name: 'transactionsReports',
    component: TransactionsReport,
    meta: {
      title: 'Транзакционный отчет'
    }
  },
  {
    path: '/cards-reports',
    name: 'cardsReports',
    component: CardsReport,
    meta: {
      title: 'Отчет по оборотам карт'
    }
  },
  {
    path: '/payments-reports',
    name: 'paymentReports',
    component: PaymentsReport,
    meta: {
      title: 'Платежный отчет'
    }
  },
  {
    // path: "*",
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/references',
    name: 'references',
    redirect: '/references/vinks',
    children: [
      {
        path: '/references/vinks',
        name: 'references.vinks',
        component: Vinks,
        meta: {
          title: 'Справочник - ВИНКи'
        }
      },
      {
        path: '/references/vinks-partner',
        name: 'references.vinksPartner',
        component: VinksPartner,
        meta: {
          title: 'Справочник - ВИНКи партнеров'
        }
      },
      {
        path: '/references/regions',
        name: 'references.regions',
        component: Regions,
        meta: {
          title: 'Справочник - Регионы'
        }
      },
      {
        path: '/references/fuel-types',
        name: 'references.fuelTypes',
        component: FuelTypes,
        meta: {
          title: 'Справочник - Типы топлива'
        }
      },
      {
        path: '/references/vinks-partner-azs',
        name: 'references.vinksPartnerAZS',
        component: vinksPartnerAZS,
        meta: {
          title: 'Справочник - ВИНКи партнерских АЗС'
        }
      },
      {
        path: '/references/gold-azs',
        name: 'references.goldAZS',
        component: GoldAZS,
        meta: {
          title: 'Справочник - Золотые АЗС'
        }
      }
    ]
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
    meta: {
      title: 'Аналитика'
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsPage,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let roleId = localStorage.getItem('role')
  if (to.name === 'main' && roleId !== null) {
    roleId = Number(roleId)
    if (roleId === 1) {
      router.push('/admin-dashboard/executive')
    } else {
      router.push('/dashboard')
    }
    return
  }
  if (to.name !== 'login') {
    if (!store.state.user.token) {
      return next('login')
    }
  }
  if (to.name === 'main' && roleId !== null) {
    roleId = Number(roleId)
    if (
      to.name === 'admin.dashboard.executive' ||
      to.name === 'admin.dashboard.accountant' ||
      to.name === 'admin.dashboard.operative'
    ) {
      if (roleId !== 1) {
        return next('dashboard')
      }
    }
    if (to.name === 'dashboard' && roleId === 1) {
      return next('admin-dashboard/executive')
    }
  }
  return next()
})

router.afterEach((to) => {
  document.title = to.meta.title || 'SmartOil'
  if (window.innerWidth <= 1000) {
    store.commit('TOGGLE_MENU', false)
  }
})

export default router
