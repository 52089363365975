import * as type from './types';
import actions from './actions';
import api from '../../../api';
import {GET_FUEL_PROVIDER_PARTNERS_AZS_REFERENCES, GET_GOLD_AZS_REFERENCES} from "./types";

const state = {
    fuelProviderReferences: [],
    fuelProviderPartnerReferences: [],
    fuelTypesReferences: [],
    regionsReferences: [],
    fuelProviderPartnersAZSReferences: [],
    goldAZSReferences: [],
};

const mutations = {
    [type.GET_FUEL_PROVIDER_REFERENCES](state, payload) {
        state.fuelProviderReferences = payload
    },
    [type.GET_FUEL_PROVIDER_PARTNER_REFERENCES](state, payload) {
        state.fuelProviderPartnerReferences = payload
    },
    [type.GET_FUEL_TYPES_REFERENCES](state, payload) {
        state.fuelTypesReferences = payload
    },
    [type.GET_REGIONS_REFERENCES](state, payload) {
        state.regionsReferences = payload
    },
    [type.GET_FUEL_PROVIDER_PARTNERS_AZS_REFERENCES](state, payload) {
        state.fuelProviderPartnersAZSReferences = payload
    },
    [type.GET_GOLD_AZS_REFERENCES](state, payload) {
        state.goldAZSReferences = payload
    },
};

const getters = {
};

export default {
    state,
    mutations,
    actions,
    getters,
};
