import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/main.scss'
import TableComponent from '@/components/table.vue'
import PageHeader from '@/components/PageHeader.vue'

import vClickOutside from 'click-outside-vue3'

import CanvasJSChart from '@canvasjs/vue-charts'

const app = createApp(App)
app.component('TableComponent', TableComponent)
app.component('PageHeader', PageHeader)

import vSelect from 'vue-select'
// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  // Deselect: {
  //     render: () => h('span', '❌'),
  // },
  OpenIndicator: {
    render: () =>
      h(
        'span',
        <svg
          class="select-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15 11L12 14L9 11"
            stroke="#B0B1B2"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )
  }
})

app.component('vSelect', vSelect)

// https://github.com/peshanghiwa/vue-awesome-paginate
import VueAwesomePaginate from 'vue-awesome-paginate'
import 'vue-awesome-paginate/dist/style.css'
app.use(VueAwesomePaginate)

app.use(store).use(router).use(vClickOutside).use(CanvasJSChart).mount('#app')
