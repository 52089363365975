<template>
  <div class="popup" :class="{'wide': userEdit.role?.id === 3}">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Редактирование пользователя</h4>

    <div class="form fake">
      <div class="left" :class="{'full-width': userEdit.role?.id !== 3}">
        <div class="form__item">
          <div class="label">
            Роль
          </div>
          <div class="input">
            <v-select :options="rolesOptions"
                      label="name"
                      v-model="userEdit.role"
                      :clearable="false"
                      :searchable="false"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="right" v-if="userEdit.role?.id === 3"></div>
    </div>

    <div class="separator"></div>

    <div v-if="!userEdit.role?.id || userEdit.role?.id === 1 || userEdit.role?.id === 2" class="form">
      <div class="form__item">
        <div class="label">
          Ф.И.О. *
        </div>
        <div class="input">
          <input type="text" v-model="userEdit.name">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Email *
        </div>
        <div class="input">
          <input
            :class="{'invalid': !emailValid}"
            type="email"
            v-model="userEdit.login"
            @focus="emailValid = true"
            @update:model-value="validateEmail(userEdit.login)"
            @blur="validateEmail(userEdit.login)"
          >
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Номер телефона
          для связи
        </div>
        <div class="input">
          <input type="text" v-model="userEdit.phone">
        </div>
      </div>
      <div class="form__item" v-if="userEdit.role?.id === 2">
        <div class="label">
          Amo ID
        </div>
        <div class="input">
          <input type="text" v-model="userEdit.amo_id">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Статус
        </div>
        <div class="input">
          <v-select :class="{'green': userEdit.status_id === 1, 'red': userEdit.status_id === 2}"
                    :options="user_statuses"
                    label="name"
                    :reduce="option => option.id"
                    v-model="userEdit.status_id"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
    </div>

    <div v-if="!userEdit.role?.id || userEdit.role?.id === 1 || userEdit.role?.id === 2" class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disabledSubmitButton" class="button btn-primary" @click="setNewUserData()">Сохранить</button>
    </div>

    <template v-if="userEdit.role?.id === 3">
      <clientForm
        :client="userEdit"
        :edit="true"
      />
    </template>

    <div v-if="userEdit.role?.id === 3" class="form fake">
      <div class="left"></div>
      <div class="right">
        <div class="actions">
          <button class="button btn-primary" @click="closeAll()">Отмена</button>
          <button class="button btn-primary" :disabled="disabledSubmitButton" @click="setNewCustomerData()">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {validation} from '@/mixins/validation'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import {mapActions, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import clientForm from "../clientForm.vue";
import {toFormData} from "@/utils/toFormData";
export default {
  name: "editUserPopup",
  mixins: [popups, validation],
  components: {
    VueDatePicker,
    vSelect,
    clientForm
  },
  data: () => ({
    /*OpenIndicator: {
      render: createElement => createElement('span', {class: {'toggle': true}}),
    },*/
    loading: false,
    emailValid: true,
    userEdit: {
      name: '',
      login: '',
      type: null,
      phone: '',
      balance: 0,
      correction_balance: 0,
      manager_id: '',
      manager: null,
      discount: 0,
      limit: 0,
      surety: '',
      surety_file: null,
      surety_at: null,
      inn: '',
      kpp: '',
      address: '',
      ogrn: '',
      account_number: '',
      bank_name: '',
      bik: '',
      correspondent_account: '',
      okpo: '',
      ceo_name: '',
      ceo_phone: '',
      role: null,
      status_id: null,
      charter_file: null,
      certificate_of_registration_file: null,
      extract_from_egryul_file: null,
      director_order_file: null,
      amo_id: '',
      integration_id: null,
      payment_integration_id: null,
    }
  }),
  computed: {
    ...mapState({
      user: state => state.popups.user,
      roles: state => state.user.roles,
      managers: state => state.admin.managers,
      user_statuses: state => state.user.user_statuses,
      surety_files_to_add: state => state.admin.surety_files_to_add,
      surety_files_to_remove: state => state.admin.surety_files_to_remove,
      supply_files_to_remove: state => state.admin.supply_files_to_remove,
      supply_files_to_add: state => state.admin.supply_files_to_add,
    }),
    managerOptions() {
      return this.managers
    },
    rolesOptions() {
      return this.roles.filter(item => item.name !== 'Водитель')
    },
    disabledSubmitButton() {
      if (this.userEdit.role && this.userEdit.role.id === 3) {
        return !this.userEdit.employee || !this.userEdit.name || !this.userEdit.login || !this.userEdit.manager || !this.userEdit.integration_id || !this.userEdit.payment_integration_id
      } else if (this.userEdit.role && this.userEdit.role.id !== 3) {
        return !this.userEdit.name ||
          !this.userEdit.login ||
          this.emailValid === false ||
          !this.userEdit.role// ||
        // !this.userEdit.phone
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers',
      editCustomer: 'editCustomer',
      createUser: 'createUser',
      getUsersSearch: 'getUsersSearch',
      editUser: 'editUser',
      getCustomerById: 'getCustomerById',
    }),
    async fetchOptions (search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getUsersSearch({
          search,
          type: 2
        })
        loading(false)
      }
    },
    async setNewUserData() {
      this.loading = true

      let editUserData = {
        userData: {
          name: this.userEdit.name,
          login: this.userEdit.login,
          type: this.userEdit.role.id,
          phone: this.userEdit.phone,
          status_id: this.userEdit.status_id
        }
      }

      if (this.userEdit.role?.id === 2) {
        editUserData.userData.amo_id = this.userEdit.amo_id
      }

      if (this.user) {
        editUserData.id = this.userEdit.id
        await this.editUser(editUserData)
      } else {
        await this.createUser(editUserData)
      }

      this.loading = false
      //this.closeAll()
    },
    async setNewCustomerData() {
      this.loading = true

      /*this.userEdit.manager_id = this.userEdit.manager?.manager_id
      this.userEdit.type = this.userEdit.role.id
      this.userEdit.company_name = this.userEdit.employee

      let editUserData = {
        // userData: {
        //   name: this.userEdit.name,
        //   login: this.userEdit.email,
        //   type: this.userEdit.role.id,
        //   phone: this.userEdit.phone,
        //   balance: this.userEdit.balance,
        //   manager_id: this.userEdit.manager_id,
        //   discount: this.userEdit.discount,
        //   limit: this.userEdit.limit,
        //   surety: this.userEdit.surety,
        //   surety_file: this.userEdit.surety_file,
        //   surety_at: this.userEdit.surety_at,
        //   inn: this.userEdit.inn,
        //   kpp: this.userEdit.kpp,
        //   address: this.userEdit.address,
        //   ogrn: this.userEdit.ogrn,
        //   account_number: this.userEdit.account_number,
        //   bank_name: this.userEdit.bank_name,
        //   bik: this.userEdit.bik,
        //   correspondent_account: this.userEdit.correspondent_account,
        //   okpo: this.userEdit.okpo,
        //   ceo_name: this.userEdit.ceo_name,
        //   ceo_phone: this.userEdit.ceo_phone
        // }
        userData: this.userEdit
      }*/

      let editUserData = {
        login: this.userEdit.login,
        name: this.userEdit.name,
        status_id: this.userEdit.status_id,
        type: this.userEdit.role.id,
        balance: this.userEdit.balance,
        correction_balance: this.userEdit.correction_balance === null || this.userEdit.correction_balance ===  '' ? 0 : this.userEdit.correction_balance,
        phone: this.userEdit.phone,
        manager_id: this.userEdit.manager.id,
        // discount: this.userEdit.discount,
        limit: this.userEdit.limit,
        surety: this.userEdit.surety,
        // surety_at: this.userEdit.surety_at,
        inn: this.userEdit.inn,
        kpp: this.userEdit.kpp,
        address: this.userEdit.address,
        ogrn: this.userEdit.ogrn,
        account_number: this.userEdit.account_number,
        bank_name: this.userEdit.bank_name,
        bik: this.userEdit.bik,
        correspondent_account: this.userEdit.correspondent_account,
        okpo: this.userEdit.okpo,
        ceo_name: this.userEdit.ceo_name,
        ceo_phone: this.userEdit.ceo_phone,
        delay: this.userEdit.delay === null ? '' : this.userEdit.delay,
        company_name: this.userEdit.employee,
        contract_number: this.userEdit.contract_number === null || this.userEdit.contract_number === 'null' ? '' : this.userEdit.contract_number,
        integration_id: this.userEdit.integration_id,
        payment_integration_id: this.userEdit.payment_integration_id,
        // contract_date: this.userEdit.contract_date,
        // surety_file: this.userEdit.surety_file,
        // charter_files: this.userEdit.charter_files,
        // certificate_of_registration_files: this.userEdit.certificate_of_registration_files,
        // extract_from_egryul_files: this.userEdit.extract_from_egryul_files,
      }

      if (this.userEdit.surety_at_model) {
        editUserData.surety_at = this.userEdit.surety_at_model
      }

      if (this.userEdit.contract_date_model) {
        editUserData.contract_date = this.userEdit.contract_date_model
      }

      if (this.userEdit.charter_file) {
        editUserData.charter_file = this.userEdit.charter_file
      }

      if (this.userEdit.certificate_of_registration_file) {
        editUserData.certificate_of_registration_file = this.userEdit.certificate_of_registration_file
      }

      if (this.userEdit.extract_from_egryul_file) {
        editUserData.extract_from_egryul_file = this.userEdit.extract_from_egryul_file
      }

      if (this.userEdit.director_order_file) {
        editUserData.director_order_file = this.userEdit.director_order_file
      }

      let data = {
        form_data: toFormData(editUserData),
        id: this.userEdit.id
      }

      if (this.surety_files_to_remove && this.surety_files_to_remove.length > 0) {
        for (let key in this.surety_files_to_remove) {
          if (this.surety_files_to_remove.hasOwnProperty(key)) {
            console.log(this.surety_files_to_remove[key])
            //filesArr.push(this.files[key])
            data.form_data.append("delete_surety_files[]", this.surety_files_to_remove[key].id);
          }
        }
      }

      if (this.surety_files_to_add && this.surety_files_to_add.length > 0) {
        for (let key in this.surety_files_to_add) {
          if (this.surety_files_to_add.hasOwnProperty(key)) {
            console.log(this.surety_files_to_add[key])
            //filesArr.push(this.files[key])
            data.form_data.append("surety_file[]", this.surety_files_to_add[key].file);
          }
        }
      }

      if (this.supply_files_to_remove && this.supply_files_to_remove.length > 0) {
        for (let key in this.supply_files_to_remove) {
          if (this.supply_files_to_remove.hasOwnProperty(key)) {
            console.log(this.supply_files_to_remove[key])
            //filesArr.push(this.files[key])
            data.form_data.append("delete_supply_contract_files[]", this.supply_files_to_remove[key].id);
          }
        }
      }

      if (this.supply_files_to_add && this.supply_files_to_add.length > 0) {
        for (let key in this.supply_files_to_add) {
          if (this.supply_files_to_add.hasOwnProperty(key)) {
            console.log(this.supply_files_to_add[key])
            //filesArr.push(this.files[key])
            data.form_data.append("supply_contract_files[]", this.supply_files_to_add[key].file);
          }
        }
      }

      if (this.user) {
        await this.editCustomer(data)
      } else {
        await this.createUser(data.form_data)
      }


      this.loading = false
      //this.closeAll()
    }
  },
  async mounted() {
    let date = new Date().toLocaleDateString()
    console.log(date)
    this.userEdit.surety_at = date

    if (this.user) {
      console.log(this.user.type)
      if (this.user.type === 3) {
        await this.getCustomerById({
          id: this.user.customer_id,
          is_client: false
        })

        this.userEdit = JSON.parse(JSON.stringify(this.user))
        this.userEdit.name = this.user.user.name
        this.userEdit.login = this.user.user.login
        this.userEdit.phone = this.user.user.phone
        this.userEdit.role = this.roles.find(item => item.id === 3)
        // this.userEdit.manager = this.user.manager.user;
        this.userEdit.manager = {
          id: this.user.manager.id,
          name: this.user.manager.user.name
        }
        this.userEdit.status_id = this.user.user.status_id;
      } else {
        this.userEdit = JSON.parse(JSON.stringify(this.user))
        this.userEdit.login = this.user.email
        this.userEdit.phone = this.user.phone
        this.userEdit.role = this.roles.find(item => item.id === this.user.type)
        this.user_statuses.forEach(status => {
          if (status.name === this.user.status) {
            this.userEdit.status_id = status.id;
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.separator {
  height: 1px;
  width: 100%;
  background: $back;
  margin: 15px 0;
}
.form {
  &.fake {
    margin-bottom: 0;
    .left {
      display: flex;
      width: 100%;
      &.full-width {
        .form__item{
          width: 100%;
        }
      }
    }
  }
}


.wide {
  max-width: unset;
  width: 790px;

  .form {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 6px;

    .left, .right {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2px;
    }
  }
}
</style>
