<template>
  <div class="popup small">
    <h4 class="popup__title middle">Список интеграций</h4>

    <span v-for="integration of integrations">{{ integration.id }} - {{ integration.name }}</span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Закрыть</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapState} from "vuex";
export default {
  name: "integrationList",
  mixins: [popups],
  data: () => ({}),
  computed: {
    ...mapState({
      integrations: state => state.admin.integrations,
    })
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

span {
  margin-bottom: 5px;
}

.actions {
  margin-top: 15px;
}
</style>
