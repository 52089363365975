import axios from 'axios';
const token = localStorage.getItem('token');
let params = {
  baseURL: `${process.env.VUE_APP_URL}/`,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${token}`
  },
};

const api = axios.create(params);

export default api;