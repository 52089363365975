<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Установить лимит <span v-if="showSetCardLimitCustomerPopup">для клиента</span><span v-else>на топливную карту</span></h4>

    <div class="modal-remove" v-if="removeItemData !== null">
      <div class="modal-remove__overlay" @click="removeItemData = null"></div>
      <div class="modal-remove__content">
        <p>Вы действительно хотите удалить лимит {{ getLimitName(removeItemData) }}?</p>

        <div class="actions">
          <button class="button btn-primary" @click="removeItemData = null">Нет</button>
          <button class="button btn-primary" @click="setRemove()">Да</button>
        </div>
      </div>
    </div>

    <div v-if="card" class="wrap">
      <div class="limits-list">
        <button class="btn-primary export-btn" @click="setNewItem">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Добавить новый
        </button>
        <button v-for="limit of card.activeLimits" class="limit" :key="`limit_${limit.id}`" @click="setItem(limit.id)" :class="{ 'active-date': limit.active }">
          {{ getLimitName(limit) }}
          <button @click.stop="removeItem(limit)">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </button>
      </div>
      <div class="form">
        <div v-if="showSetCardLimitCustomerPopup" class="form__item">
          <div class="label">
            Клиент
          </div>
          <div class="input">
            <v-select :options="customersOptions"
                      label="name"
                      v-model="customerSelected"
                      :clearable="false"
                      @search="fetchOptions"
            >
              <template #no-options>
                Введите имя
              </template>
            </v-select>
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            Тип лимита
          </div>
          <div class="input">
            <v-select :options="limitTypeOptions"
                      label="name"
                      v-model="limitType"
                      :clearable="false"
                      :searchable="false"
                      @option:selected="typeSelected"
            ></v-select>
          </div>
        </div>
        <div class="form__item" v-if="!showSetCardLimitCustomerPopup">
          <div class="label">
            Тип топлива
          </div>
          <div class="input">
            <v-select :options="fuelTypesArr"
                      label="name"
                      v-model="fuelType"
                      :clearable="false"
                      :searchable="false"
                      @option:selected="typeSelected"
            ></v-select>
          </div>
        </div>
        <div class="form__item" v-if="limitType && limitType.name === 'Рубли'">
          <div class="label">
            Сумма руб.
          </div>
          <div class="input">
            <input type="number" v-model="amount_limit">
          </div>
        </div>
        <div class="form__item" v-if="limitType && limitType.name === 'Литры'">
          <div class="label">
            Объем в литрах
          </div>
          <div class="input">
            <input type="number" v-model="volume_limit">
          </div>
        </div>
        <div class="form__item">
          <div class="label">
            По времени
          </div>
          <div class="input">
            <v-select :options="cardLimitsArr"
                      label="name"
                      v-model="limit"
                      :clearable="false"
                      :searchable="false"
                      @option:selected="typeSelected"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableSubmitButton" class="button btn-primary" @click="setCardLimit()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";
export default {
  name: "setCardLimitPopup",
  components: {vSelect, VueDatePicker},
  mixins: [popups],
  data: () => ({
    loading: false,
    limitType: null,
    fuelType: null,
    limitTypeOptions: [],
    customerSelected: null,
    admin_amount_limit: '',
    amount_limit: '',
    volume_limit: '',
    cardLimitsArr: [],
    limit: null,
    fuelTypesArr: [],
    setNewLimit: true,
    removeItemData: null
  }),
  computed: {
    ...mapState({
      card: state => state.popups.card,
      cardsChecked: state => state.popups.cardsChecked,
      showSetCardLimitCustomerPopup: state => state.popups.showSetCardLimitCustomerPopup,
      cardLimits: state => state.user.card_limits,
      customer: state => state.popups.customer,
      // customers: state => state.admin.customers,
      customers: state => state.admin.managers,
      cardCatalog: state => state.admin.cardCatalog,
      fuelTypes: state => state.user.fuelTypes,
      userRole: state => state.user.userRole,
    }),
    disableSubmitButton() {
      let flag = true;
      if (this.limit && this.amount_limit !== '') {
        flag = false;
      }
      if (this.limit && this.volume_limit !== '') {
        flag = false;
      }
      return flag;
    },
    customersOptions() {
      let options = []
      if (this.customers && this.customers.length > 0) {
        for (let i = 0; i < this.customers.length; i++) {
          let customer = this.customers[i]
          options.push(customer)
        }
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      // getCustomers: 'getCustomers',
      getUsersSearch: 'getUsersSearch',
      getCardLimits: 'getCardLimits',
      bunchSetCardsLimit: 'bunchSetCardsLimit',
    }),
    ...mapMutations({
      setCard: 'SET_CARD',
      setCustomer: 'SET_CUSTOMER',
    }),
    async typeSelected() {
      console.log(this.card)
      if (!this.limitType || !this.limit || !this.fuelType) {
        this.amount_limit = '';
        this.volume_limit = ''
      }/* else {
        let limit = this.card.activeLimits.find(item => (item.currency_code === this.limitType.id || item.currencyLimit?.id === this.limitType.id) && (item.fuel_code === this.fuelType.id || item.fuel_code === this.fuelType.id) && item.duration_code === this.fuelType.id)
        this.amount_limit = limit.amount_limit;
        this.volume_limit = limit.volume_limit;
      }*/
    },
    getLimitName(limit) {
      let str = '';
      if (this.card.integration_id === 1 || this.card.integration_id === 2) {
        if (this.userRole === 1) {
          str = `${limit.fuel_name} - ${limit.admin_amount_limit ? limit.admin_amount_limit : 0} (клиентский ${limit.amount_limit ? limit.amount_limit : 0}) ${ limit.currency_name }\\${limit.duration_name}`
        } else {
          str = `${limit.fuel_name} - ${limit.amount_limit ? limit.amount_limit : 0} ${ limit.currency_name }\\${limit.duration_name}`
        }
      } else if (this.card.integration_id !== 1 && this.card.integration_id !== 2) {
        if (this.userRole === 1) {
          str = `${limit.fuelType?.name} - ${limit.admin_amount_limit ? limit.admin_amount_limit : 0} (клиентский ${limit.amount_limit ? limit.amount_limit : 0}) ${limit.currencyLimit?.name}\\${limit.dateLimit?.name}`
        } else {
          str = `${limit.fuelType?.name} - ${limit.amount_limit ? limit.amount_limit : 0} ${limit.currencyLimit?.name}\\${limit.dateLimit?.name}`
        }
      }
      return str;
    },
    async setRemove() {
      await this.setItem(this.removeItemData.id, true)
      await this.setCardLimit(true)
    },
    async setNewItem() {
      this.limitType = null
      this.limit = null
      this.fuelType = null

      this.card.activeLimits.forEach(item => {
        item.active = false;
      })
    },
    async setItem(id, toRemove = false) {
      let limit = this.card.activeLimits.find(item => item.id === id);
      this.card.activeLimits.forEach((item, i) => {
        item.active = id === item.id;
      })

      if ((this.card && (this.card.integration_id === 1 || this.card.integration_id === 2)) || (this.cardsChecked && this.cardsChecked.length > 0 && (this.cardsChecked[0].integration_id === 1 || this.cardsChecked[0].integration_id === 2))) {
        // data['fuel_code'] = this.fuelType.id;
        // data['duration_code'] = this.limit.id;
        // data['currency_code'] = this.limitType.id;
        this.fuelType = this.fuelTypesArr.find(item => item.id === limit.fuel_code);
        this.limit = this.cardLimitsArr.find(item => item.id === limit.duration_code);
        this.limitType = this.limitTypeOptions.find(item => item.id === limit.currency_code);
      } else {
        this.fuelType = this.fuelTypesArr.find(item => item.id === limit.fuelType.id);
        this.limit = this.cardLimitsArr.find(item => item.id === limit.dateLimit.id);
        this.limitType = this.limitTypeOptions.find(item => item.id === limit.currencyLimit.id);
      }

      if (toRemove === false) {
        if (limit.currency_code === "LIT" || limit.currency_limit_id === 2) {
          this.volume_limit = this.userRole === 1 ? limit.admin_amount_limit ? limit.admin_amount_limit : 0 : limit.amount_limit;
        } else {
          this.amount_limit = this.userRole === 1 ? limit.admin_amount_limit ? limit.admin_amount_limit : 0 : limit.amount_limit;
        }
      } else {
        this.volume_limit = '0'
        this.amount_limit = '0'
        this.admin_amount_limit = '0'
      }

    },
    async removeItem(limit) {
      this.removeItemData = limit
    },
    async setCardLimit(toRemove = false) {
      this.loading = true

      let cards_ids = []
      let customer_id = null

      if (this.showSetCardLimitCustomerPopup) {
        customer_id = this.customerSelected.customer_id ? this.customerSelected.customer_id : this.customerSelected.id
      } else {
        if (this.card) {
          cards_ids = [this.card.id]
        } else if (this.cardsChecked) {
          let cardIds = []
          for (let i = 0; i < this.cardsChecked.length; i++) {
            let card = this.cardsChecked[i]
            if (card.id) {
              cardIds.push(card.id)
            }
          }
          cards_ids = cardIds
        }
      }

      let data = {
        cards_ids,
      }

      console.log('this.amount_limit: ', this.amount_limit)
      console.log('this.volume_limit: ', this.volume_limit)

      if (this.amount_limit !== '') {
        console.log('this.amount_limit !== \'\'', this.amount_limit !== '')
        data['amount_limit'] = this.amount_limit
      }

      if (this.volume_limit !== '') {
        console.log('this.volume_limit !== \'\'', this.volume_limit !== '')
        data['amount_limit'] = this.volume_limit
      }

      console.log('TO REMOVE: ', toRemove)
      if (toRemove) {
        data['amount_limit'] = 0
        if (this.userRole === 1) data['admin_amount_limit'] = 0
      }

      if ((this.card && (this.card.integration_id === 1 || this.card.integration_id === 2)) || (this.cardsChecked && this.cardsChecked.length > 0 && (this.cardsChecked[0].integration_id === 1 || this.cardsChecked[0].integration_id === 2))) {
        data['fuel_name'] = this.fuelType.name;
        data['fuel_code'] = this.fuelType.id;
        data['duration_name'] = this.limit.name;
        data['duration_code'] = this.limit.id;
        data['currency_name'] = this.limitType.name;
        data['currency_code'] = this.limitType.id;
      } else {
        data['fuel_type_id'] = this.fuelType.id;
        data['date_limit_id'] = this.limit.id;
        data['currency_limit_id'] = this.limitType.id;
      }

      console.log(data)

      await this.bunchSetCardsLimit(data)

      this.loading = false
      //this.closeAll()
    },
    async fetchOptions(search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getUsersSearch({
          search,
          type: 3
        })
        loading(false)
      }
    },
  },
  async mounted() {

    if ((this.card && (this.card.integration_id === 1 || this.card.integration_id === 2)) || (this.cardsChecked && this.cardsChecked.length > 0 && (this.cardsChecked[0].integration_id === 1 || this.cardsChecked[0].integration_id === 2))) {
      this.limitTypeOptions = [];
      this.cardCatalog.currencylist.forEach(item => {
        this.limitTypeOptions.push({
          id: item.Code ?? item.code,
          name: item.Name ? item.Name.charAt(0).toUpperCase() + item.Name.slice(1) : item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
      //this.limitType = this.limitTypeOptions[0]
      this.cardLimitsArr = [];
      this.cardCatalog.durationlist.forEach(item => {
        this.cardLimitsArr.push({
          id: item.Code ?? item.code,
          name: item.Name ? item.Name.charAt(0).toUpperCase() + item.Name.slice(1) : item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
      //this.limit = this.cardLimitsArr[0]
      this.fuelTypesArr = [];
      this.cardCatalog.goodlist.forEach(item => {
        this.fuelTypesArr.push({
          id: item.Code ?? item.code,
          name: item.Name ? item.Name.charAt(0).toUpperCase() + item.Name.slice(1) : item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
      //this.fuelType = this.fuelTypesArr[0]
    } else {
      this.limitTypeOptions = this.cardCatalog.currencyLimitTypes;
      //this.limitType = this.limitTypeOptions[0]

      this.cardLimitsArr = this.cardCatalog.dateLimitTypes;
      //this.limit = this.cardLimitsArr[0]

      this.fuelTypesArr = this.cardCatalog.fuelTypes;
      //this.fuelType = this.fuelTypesArr[0]
    }
  },
  beforeUnmount() {
    this.setCard(null)
    this.setCustomer(null)
  }
}
</script>

<style lang="scss">
.vs__selected {
  text-align: left;
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";

.popup .form__item .label {
  min-width: 120px;
}

.wrap {
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.limits-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex-shrink: 0;
  max-width: 200px;

  .export-btn {
    height: 36px;
    margin-bottom: 28px;
    box-sizing: border-box;
    max-height: 36px;
  }

  .limit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 40px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #F1F8EB;
    background: none;
    text-align: left;
    transition: all ease .3s;

    &:hover {
      background: #F1F8EB;
    }

    &.active-date {
      background: #F1F8EB;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 3px 4px 3px 3px;
      background: none;
      border: none;
      border-radius: 6px;
      transition: all ease .3s;

      &:hover {
        background: #85ff8e;
      }
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
}

.modal-remove {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 11;

  &__overlay {
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0.3;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    transition: none!important;
    transform: none!important;
  }

  &__content {
    position: relative;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(109, 181, 52, 0.30);
    padding: 30px;
    z-index: 10;
  }
}
</style>
