<template>
  <div class="page-container">
    <PageHeader icon="reports-active.svg" title="Отчеты" />
    <div class="page-content">
      <tabs
        v-if="tabs && tabs.length > 0"
        :tabsNames="tabs"
        @firstTabActive="firstTabActive = $event"
      />
      <div
        class="tab-content table"
        :class="{ 'tab-content__active-first': firstTabActive }"
        :style="{ marginTop: tabs && tabs.length > 0 ? '0' : '30px' }"
      >
        <TableComponent
          :noData="noData"
          :columns="columns"
          :rows="tableRows"
          :title="'Задолженности'"
          :isBordered="true"
          @downloadXSLX="downloadDebtReport"
          :actionType="'debtReportActions'"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @regetData="regetData($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/tabs.vue'
  import { mapActions, mapMutations, mapState } from 'vuex'

  export default {
    name: 'DebtReport',
    components: {
      Tabs
    },
    data() {
      return {
        firstTabActive: false,
        tabsNames: [
          { name: 'Транзакционный отчет', linkName: 'reports.transactions' },
          { name: 'Платежный отчет', linkName: 'reports.payment' },
          { name: 'Отчет по оборотам карт', linkName: 'reports.cards' },
          { name: 'Зарплатный отчет', linkName: 'reports.salary' },
          { name: 'Отчет Сверка', linkName: 'reports.revise' },
          { name: 'Отчет по задолженностям', linkName: 'reports.debt' },
          { name: 'Акт сверки', linkName: 'reports.reconciliation' }
        ],
        tableColumns: [
          {
            name: 'Имя или название организации',
            field: 'employee',
            filter_name: 'employee',
            filter_value: '',
            order: '',
            multiple_search: true,
            search_items: []
          },
          {
            name: 'Баланс',
            field: 'balance',
            filter_name: 'balance',
            filter_value: '',
            filter_name_from: 'balance_from',
            filter_value_from: '',
            filter_name_to: 'balance_to',
            filter_value_to: '',
            from_to_fields: true,
            order: ''
          },
          {
            name: 'Могут уходить в минус с учетом КЛ',
            field: 'into_minus',
            filter_name: 'into_minus',
            filter_value: '',
            filter_name_from: 'balance_from',
            filter_value_from: '',
            filter_name_to: 'balance_to',
            filter_value_to: '',
            from_to_fields: true,
            order: ''
          },
          {
            name: 'Кредитный лимит',
            field: 'limit',
            filter_name: 'limit',
            filter_value: '',
            filter_name_from: 'limit_from',
            filter_value_from: '',
            filter_name_to: 'limit_to',
            filter_value_to: '',
            from_to_fields: true,
            order: ''
          },
          {
            name: 'Оборот за текущий месяц',
            field: 'monthly_turnover',
            filter_name: 'monthly_turnover',
            filter_value: '',
            filter_name_from: 'monthly_turnover_from',
            filter_value_from: '',
            filter_name_to: 'monthly_turnover_to',
            filter_value_to: '',
            from_to_fields: true,
            order: ''
          },
          {
            name: 'Менеджер',
            field: 'manager_name',
            filter_name: 'manager_id',
            filter_value: '',
            order: '',
            multiple: true
          }
        ],
        tableRows: [],
        noData: false,
        loading: false,
        totalCount: 0,
        pageSize: 0,
        pageCount: 0,
        currentPage: 0
      }
    },
    computed: {
      ...mapState({
        token: (state) => state.user.token,
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        debtReport: (state) => state.customer.debtReport
      }),
      tabs() {
        switch (this.userRole) {
          case 3:
            return []
          case 2:
            return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
          case 1:
            return [
              this.tabsNames[0],
              this.tabsNames[4],
              this.tabsNames[3],
              this.tabsNames[2],
              this.tabsNames[5],
              this.tabsNames[6]
            ]
        }
      }
    },
    watch: {
      async debtReport(val) {
        await this.setTablesData()
      }
    },
    methods: {
      ...mapActions({
        getDebtReport: 'getDebtReport'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      async downloadDebtReport() {
        await this.getDebtReport(true)
      },
      async regetData(data) {
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'perPage',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)
        await this.getDebtReport()
      },
      async setTablesData() {
        if (!this.debtReport) return

        this.totalCount = this.debtReport.total_count
        this.pageSize = this.debtReport.page_size
        this.pageCount = this.debtReport.page_count
        this.currentPage = this.debtReport.current_page

        this.tableRows = this.debtReport.data || []
        this.noData = !this.tableRows.length

        if (this.debtReport.sum_parameters) {
          this.additionalRows[0].debt_amount = this.debtReport.sum_parameters.total_debt
        }

        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)
      this.resetPaginationFilters()
      this.setColumns(this.tableColumns)

      await this.getDebtReport().catch(() => {
        this.noData = true
      })
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';
  .separator {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 1px;
    background: $back;
    width: 100%;
  }

  .margin {
    margin-top: 30px;
  }
</style>
