<template>
  <div class="page-container">
    <PageHeader
      icon="reports-active.svg"
      :title="userRole === 3 ? 'Транзакционный отчет' : 'Отчеты'"
    />
    <div class="page-content">
      <tabs
        v-if="tabs && tabs.length > 0"
        :tabsNames="tabs"
        @firstTabActive="firstTabActive = $event"
      />
      <div
        class="tab-content table"
        :class="{ 'tab-content__active-first': firstTabActive }"
        :style="{ marginTop: tabs && tabs.length > 0 ? '0' : '30px' }"
      >
        <TableComponent
          v-if="userRole === 2"
          :noData="noData"
          :columns="columns"
          :rows="tableRows_1"
          :title="'Транзакции'"
          :isBordered="true"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          @downloadXSLX="downloadTransactionReportXLSX"
          :actionType="'transactionReportActions'"
          :datePicker="true"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @regetData="regetData($event)"
        />
        <TableComponent
          v-else
          :noData="noData"
          :columns="columns"
          :rows="tableRows_1"
          :title="userRole !== 1 ? 'Транзакционный отчет' : 'Транзакции'"
          :isBordered="true"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          @downloadXSLX="downloadTransactionReportXLSX"
          :actionType="'transactionReportActions'"
          :datePicker="true"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @regetData="regetData($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/tabs.vue'
  import { mapActions, mapMutations, mapState } from 'vuex'

  export default {
    name: 'TransactionsReport',
    components: {
      Tabs
    },
    data() {
      return {
        firstTabActive: false,
        tabsNames: [
          { name: 'Транзакционный отчет', linkName: 'reports.transactions' },
          { name: 'Платежный отчет', linkName: 'reports.payment' },
          { name: 'Отчет по оборотам карт', linkName: 'reports.cards' },
          { name: 'Зарплатный отчет', linkName: 'reports.salary' },
          { name: 'Отчет Сверка', linkName: 'reports.revise' },
          { name: 'Отчет по задолженностям', linkName: 'reports.debt' },
          { name: 'Акт сверки', linkName: 'reports.reconciliation' }
        ],
        tableColumns_1: [
          {
            name: 'Дата',
            field: 'datetime',
            // filter_name: 'datetime',
            // filter_value: '',
            order: '',
            date_type: true
          },
          {
            name: 'Номер карты',
            field: 'card_number',
            filter_name: 'card_number',
            filter_value: '',
            order: '',
            multiple_search: true,
            search_items: []
          },
          {
            name: 'Держатель',
            field: 'card_holder',
            filter_name: 'card_holder',
            filter_value: '',
            order: ''
          },
          {
            name: 'Клиент',
            field: 'user_name',
            filter_name: 'customer_id',
            filter_value: '',
            order: '',
            multiple: true
          },
          {
            name: 'Номер договора',
            field: 'contract_number',
            filter_name: 'contract_number',
            filter_value: '',
            order: ''
          },
          {
            name: 'Регион',
            field: 'region',
            filter_name: 'region',
            filter_value: '',
            order: ''
          },
          {
            name: 'Адрес',
            field: 'station_address',
            filter_name: 'station_address',
            filter_value: '',
            order: ''
          },
          {
            name: '№ АЗС',
            field: 'station_number',
            filter_name: 'station_number',
            reducer: 'number',
            filter_value: '',
            order: ''
          },
          // {
          //   name: 'Координаты АЗС',
          //   field: 'coordinates',
          //   filter_name: '',
          //   filter_value: '',
          //   order: ''
          // },
          {
            name: 'ВИНК',
            field: 'fuel_provider_id',
            filter_name: 'fuel_provider_id',
            filter_value: '',
            order: '',
            multiple: true
          },
          {
            name: 'Вид топлива',
            field: 'fuel_type',
            filter_name: 'fuel_type',
            filter_value: '',
            order: '',
            multiple: true
          },
          {
            name: 'Кол-во, л',
            field: 'fuel_count_rounded',
            filter_name: 'fuel_count',
            filter_value: '',
            order: '',
            filter_name_from: 'fuel_count_from',
            filter_value_from: '',
            filter_name_to: 'fuel_count_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Цена стелы, руб.',
            field: 'stella_rounded',
            filter_name: 'stella',
            filter_value: '',
            order: '',
            filter_name_from: 'stella_from',
            filter_value_from: '',
            filter_name_to: 'stella_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Цена клиента, руб.',
            field: 'customers_price_rounded',
            filter_name: 'customers_price',
            filter_value: '',
            order: '',
            filter_name_from: 'customers_price_from',
            filter_value_from: '',
            filter_name_to: 'customers_price_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Скидка/наценка, %',
            field: 'discount_per_liter',
            filter_name: 'discount_per_liter',
            filter_value: '',
            order: '',
            filter_name_from: 'discount_per_liter_from',
            filter_value_from: '',
            filter_name_to: 'discount_per_liter_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Сумма без скидки, руб.',
            field: 'amount_without_discount_rounded',
            filter_name: 'amount_without_discount',
            filter_value: '',
            order: '',
            filter_name_from: 'amount_without_discount_from',
            filter_value_from: '',
            filter_name_to: 'amount_without_discount_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Сумма со скидкой, руб.',
            field: 'discounted_amount_rounded',
            filter_name: 'discounted_amount',
            filter_value: '',
            order: '',
            filter_name_from: 'discounted_amount_from',
            filter_value_from: '',
            filter_name_to: 'discounted_amount_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Сумма скидки/наценки, руб.',
            field: 'surcharge_amount_rounded',
            filter_name: 'surcharge_amount',
            filter_value: '',
            order: '',
            filter_name_from: 'surcharge_amount_from',
            filter_value_from: '',
            filter_name_to: 'surcharge_amount_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: '',
            field: 'actions'
          }
        ],
        tableRows_1: [
          {
            region: 'Рязанская',
            address: `г. Рязань, Северная окружная дорога, 20`,
            date: `14.04.2023 14:34:23`,
            card_number: `7013420001865393`,
            gas_station_coordinates: `54.652194464962726, 39.695511270491686`,
            gas_station_number: `/0540`,
            contract_number: `Д-6833.2023`,
            fuel_type: `AdBlue`,
            quantity_litres: `100`,
            stela_price: `90,00`,
            client_price: `90,00`,
            discount_roubles: `10`,
            client_sum_roubles: `3200`,
            discount_extra_charge: `120`
          },
          {
            region: 'Рязанская',
            address: `г. Рязань, Северная окружная дорога, 20`,
            date: `14.04.2023 14:34:23`,
            card_number: `7013420001865393`,
            gas_station_coordinates: `54.652194464962726, 39.695511270491686`,
            gas_station_number: `/0540`,
            contract_number: `Д-6833.2023`,
            fuel_type: `AdBlue`,
            quantity_litres: `100`,
            stela_price: `90,00`,
            client_price: `90,00`,
            discount_roubles: `10`,
            client_sum_roubles: `3200`,
            discount_extra_charge: `120`
          }
        ],
        additionalRows_1: [
          {
            datetime: 'Сумма',
            card_number: ``,
            user_name: ``,
            contract_number: ``,
            region: ``,
            station_address: ``,
            station_number: ``,
            coordinates: ``,
            fuel_type: ``,
            fuel_count: ``,
            stela: ``,
            customers_price: ``,
            discount_per_liter: ``,
            discounted_amount: ``,
            surcharge_amount: ''
          }
        ],
        tableColumns_2: [
          {
            name: 'Дата',
            field: 'datetime',
            // filter_name: 'datetime',
            // filter_value: '',
            order: '',
            date_type: true
          },
          {
            name: 'Номер карты',
            field: 'card_number',
            filter_name: 'card_number',
            filter_value: '',
            order: ''
          },
          {
            name: 'Клиент',
            field: 'user_name',
            filter_name: 'user_name',
            filter_value: '',
            order: ''
          },
          {
            name: 'Номер договора',
            field: 'contract_number',
            filter_name: 'contract_number',
            filter_value: '',
            order: ''
          },
          {
            name: 'Регион',
            field: 'region',
            filter_name: 'region',
            filter_value: '',
            order: ''
          },
          {
            name: 'Адрес',
            field: 'station_address',
            filter_name: 'station_address',
            filter_value: '',
            order: ''
          },
          {
            name: '№ АЗС',
            field: 'station_number',
            filter_name: 'station_number',
            reducer: 'number',
            filter_value: '',
            order: ''
          },
          // {
          //   name: 'Координаты АЗС',
          //   field: 'coordinates',
          //   filter_name: '',
          //   filter_value: '',
          //   order: ''
          // },
          {
            name: 'ВИНК',
            field: 'fuel_provider_id',
            filter_name: 'fuel_provider_id',
            filter_value: '',
            order: '',
            multiple: true
          },
          {
            name: 'Вид топлива',
            field: 'fuel_type',
            filter_name: 'fuel_type',
            filter_value: '',
            order: '',
            multiple: true
          },
          {
            name: 'Кол-во, л',
            field: 'fuel_count_rounded',
            filter_name: 'fuel_count',
            filter_value: '',
            order: '',
            filter_name_from: 'fuel_count_from',
            filter_value_from: '',
            filter_name_to: 'fuel_count_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Цена стелы, руб.',
            field: 'stella_rounded',
            filter_name: 'stela',
            filter_value: '',
            order: '',
            filter_name_from: 'stela_from',
            filter_value_from: '',
            filter_name_to: 'stela_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Цена клиента, руб.',
            field: 'customers_price_rounded',
            filter_name: 'customers_price',
            filter_value: '',
            order: '',
            filter_name_from: 'customers_price_from',
            filter_value_from: '',
            filter_name_to: 'customers_price_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Скидка/наценка, %',
            field: 'discount_per_liter',
            filter_name: 'discount_per_liter',
            filter_value: '',
            order: '',
            filter_name_from: 'discount_per_liter_from',
            filter_value_from: '',
            filter_name_to: 'discount_per_liter_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Сумма со скидкой, руб.',
            field: 'discounted_amount_rounded',
            filter_name: 'discounted_amount',
            filter_value: '',
            order: '',
            filter_name_from: 'discounted_amount_from',
            filter_value_from: '',
            filter_name_to: 'discounted_amount_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Сумма без скидки, руб.',
            field: 'amount_without_discount_rounded',
            filter_name: 'amount_without_discount',
            filter_value: '',
            order: '',
            filter_name_from: 'amount_without_discount_from',
            filter_value_from: '',
            filter_name_to: 'amount_without_discount_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Сумма скидки/наценки, руб.',
            field: 'surcharge_amount_rounded',
            filter_name: 'surcharge_amount',
            filter_value: '',
            order: '',
            filter_name_from: 'surcharge_amount_from',
            filter_value_from: '',
            filter_name_to: 'surcharge_amount_to',
            filter_value_to: '',
            from_to_fields: true
          }
        ],
        tableRows_2: [
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500 000',
            year_strait: '500',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          },
          {
            client_name: 'ООО “Юнион Лаб”',
            beginning_balance: '-1 032 583,60',
            year_turnover: '-1 032 583,60',
            month_turnover: '600 000',
            current_balance: '500',
            year_strait: '500 000',
            month_strait: '50'
          }
        ],
        additionalRows_2: [
          {
            datetime: 'Сумма',
            card_number: ``,
            user_name: ``,
            contract_number: ``,
            region: ``,
            station_address: ``,
            station_number: ``,
            coordinates: ``,
            fuel_type: ``,
            fuel_count: ``,
            stela: ``,
            customers_price: ``,
            discount_per_liter: ``,
            discounted_amount: ``,
            surcharge_amount: ''
          }
        ],
        noData: false,
        loading: false,
        totalCount: 0,
        pageSize: 0,
        pageCount: 0,
        currentPage: 0
      }
    },
    watch: {
      async transaction_report(val) {
        await this.setTablesData()
      }
    },
    computed: {
      ...mapState({
        token: (state) => state.user.token,
        userRole: (state) => state.user.userRole,
        myUser: (state) => state.user.myUser,
        columns: (state) => state.popups.columns,
        transaction_report: (state) => state.customer.transaction_report,
        discountBlockDay: (state) => state.customer.discountBlockDay,
      }),
      tabs() {
        switch (this.userRole) {
          // case 3: return [this.tabsNames[0], this.tabsNames[1]];
          case 3:
            return []
          case 2:
            return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
          case 1:
            return [
              this.tabsNames[0],
              this.tabsNames[4],
              this.tabsNames[3],
              this.tabsNames[2],
              this.tabsNames[5],
              this.tabsNames[6]
            ]
        }
      }
    },
    methods: {
      ...mapActions({
        getTransactionReport: 'getTransactionReport',
        getTransactionReportXLSX: 'getTransactionReportXLSX',
        getStations: 'getStations',
        getFuelTypesReq: 'getFuelTypes',
        getFuelProviders: 'getFuelProviders',
        getDiscountBlockDay: 'getDiscountBlockDay',
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setColumns_2: 'SET_COLUMNS_2',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      async downloadTransactionReportXLSX() {
        await this.getTransactionReport(true)
      },
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'perPage',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getTransactionReport()
      },
      async setTablesData() {
        this.totalCount = this.transaction_report.total_count
        this.pageSize = this.transaction_report.page_size
        this.pageCount = this.transaction_report.page_count
        this.currentPage = this.transaction_report.current_page
        let reportData = []

        for (let i = 0; i < this.transaction_report.data.length; i++) {
          let data = this.transaction_report.data[i]
          data.actionType = 'transactionReportActions'

          reportData.push(data)
        }

        // if (this.userRole === 2) {
        //
        //   if (this.columns) {
        //     this.setColumns(this.columns)
        //   } else {
        //     this.setColumns(this.tableColumns_2)
        //   }
        //
        //   this.tableRows_2 = reportData
        //   if (!this.tableRows_2 || this.tableRows_2.length === 0) {
        //     this.noData = true
        //   }
        //
        // } else {
        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          if (this.userRole === 3) {
            this.tableColumns_1.forEach((item, index) => {
              if (item.field === 'user_name') {
                this.tableColumns_1.splice(index, 1)
              }
            })
          }
          this.setColumns(this.tableColumns_1)
        }

        this.additionalRows_1[0].fuel_count_rounded =
          this.transaction_report.sum_parameters.sum_fuel_count
        this.additionalRows_1[0].customers_price_rounded =
          this.transaction_report.sum_parameters.sum_customers_price
        this.additionalRows_1[0].discount_per_liter =
          this.transaction_report.sum_parameters.sum_discount_per_liter
        this.additionalRows_1[0].discounted_amount_rounded =
          this.transaction_report.sum_parameters.sum_discounted_amount
        this.additionalRows_1[0].surcharge_amount_rounded =
          this.transaction_report.sum_parameters.sum_surcharge_amount

        this.tableRows_1 = reportData
        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }
        // }

        this.loading = false
      }
    },
    async mounted() {
      if (this.userRole === 1) {
        await this.getDiscountBlockDay()
      }
      await this.getStations()
      await this.getFuelTypesReq()
      await this.getFuelProviders()

      this.setColumns(null)
      this.setColumns_2(null)

      this.resetPaginationFilters()

      // setTimeout(async () => {
      // }, 1000)

      await this.getTransactionReport().catch((err) => {
        if (this.userRole === 2) {
          this.setColumns(this.tableColumns_2)
          this.noData = true
        } else {
          if (this.userRole === 3) {
            this.tableColumns_1.forEach((item, index) => {
              if (item.field === 'user_name') {
                this.tableColumns_1.splice(index, 1)
              }
            })
          }
          this.setColumns(this.tableColumns_1)
          this.noData = true
        }
      })
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';
  .separator {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 1px;
    background: $back;
    width: 100%;
  }

  .margin {
    margin-top: 30px;
  }
</style>
