<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM18 17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01V18H18V17ZM4 17C4 14.34 9.33 13 12 13C14.67 13 20 14.34 20 17V20H4V17Z" fill="#6DB534"/>
        </svg>
        <span class="text">
            Управление аккаунтом
        </span>
      </div>
    </div>
<!--    <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event"/>-->
    <div class="tab-content">
      <div class="user">
        <div class="avatar">
<!--          <img src="../../assets/avatar.png" alt="avatar">-->
          <span>{{ getAvatarText() }}</span>
<!--          <button>-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">-->
<!--              <g clip-path="url(#clip0_363_11043)">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33326 1.05717C6.25322 1.05717 6.17397 1.07293 6.10002 1.10356C6.02608 1.13419 5.95889 1.17909 5.9023 1.23568L1.46489 5.67309L1.14167 6.85824L2.32682 6.53502L6.76422 2.09761C6.82082 2.04101 6.86571 1.97383 6.89634 1.89988C6.92697 1.82594 6.94274 1.74668 6.94274 1.66665C6.94274 1.58661 6.92697 1.50735 6.89634 1.43341C6.86571 1.35946 6.82082 1.29228 6.76422 1.23568C6.70763 1.17909 6.64044 1.13419 6.5665 1.10356C6.49255 1.07293 6.4133 1.05717 6.33326 1.05717ZM5.8449 0.487643C5.99973 0.423511 6.16567 0.390503 6.33326 0.390503C6.50084 0.390503 6.66679 0.423511 6.82162 0.487643C6.97645 0.551776 7.11713 0.645775 7.23563 0.764276C7.35413 0.882777 7.44813 1.02346 7.51226 1.17829C7.57639 1.33312 7.6094 1.49906 7.6094 1.66665C7.6094 1.83423 7.57639 2.00017 7.51226 2.155C7.44813 2.30983 7.35413 2.45051 7.23563 2.56901L2.73563 7.06901C2.69461 7.11003 2.6436 7.13964 2.58763 7.1549L0.754299 7.6549C0.638895 7.68637 0.515474 7.6536 0.430891 7.56901C0.346307 7.48443 0.313531 7.36101 0.345005 7.24561L0.845005 5.41227C0.860268 5.35631 0.889872 5.30529 0.930891 5.26428L5.43089 0.764276C5.54939 0.645775 5.69007 0.551776 5.8449 0.487643Z" fill="#2773FF"/>-->
<!--              </g>-->
<!--              <defs>-->
<!--                <clipPath id="clip0_363_11043">-->
<!--                  <rect width="8" height="8" fill="white"/>-->
<!--                </clipPath>-->
<!--              </defs>-->
<!--            </svg>-->
<!--          </button>-->
        </div>
        <div class="name-wrap">
         <span class="name" v-if="myUser && myUser.name">
          {{ myUser.name }}
         </span>
         <span class="role" v-if="myUser && myUser.type_name">
          {{ myUser.type_name }}
         </span>
        </div>
      </div>
      <hr class="line">
      <div class="user-requisites" v-if="myUser && myUser.name">
        <div class="input-wrap">
          <span>Ф.И.О.</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.name"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>ИНН</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.inn"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>КПП</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.kpp"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>ОГРН</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.ogrn"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Юридический адрес</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.address"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Расчетный счет</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.account_number"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Название банка</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.bank_name"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>БИК</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.bik"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Корреспондентский счет</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.correspondent_account"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>ОКПО</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.okpo"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>ФИО директора</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.ceo_name"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Номер телефона директора</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.ceo_phone"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Номер телефона для связи</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.phone"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Имя или название организации</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.employee"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Email</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.email"
                 disabled
          >
        </div>

        <div class="input-wrap">
          <span>Баланс</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.balance"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Менеджер</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.manager_user_name"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Поручитель</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.surety"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Дата поручительства</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.surety_at"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Кредитный лимит</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.limit"
                 disabled
          >
        </div>
        <div class="input-wrap">
          <span>Отсрочка</span>
          <input type="text"
                 placeholder=""
                 v-model="myUser.customer.delay"
                 disabled
          >
        </div>
      </div>
      <hr class="line">
      <div class="user-requisites" v-if="myUser && myUser.name">
        <span class="user-requisites__head">Договор на поставку</span>
        <div class="input-wrap">
          <span>Номер договора</span>
          <div class="input">
            <input type="text"
                   placeholder=""
                   v-model="myUser.customer.contract_number"
                   disabled
            >
          </div>
        </div>
        <div class="input-wrap">
          <span>Дата поставки</span>
          <div class="input">
            <input type="text"
                   placeholder=""
                   v-model="myUser.customer.contract_data"
                   disabled
            >
          </div>
        </div>
      </div>
      <div class="user-requisites" v-if="myUser && myUser.name">
        <span class="user-requisites__head">Файлы договора</span>
        <div class="input-wrap" v-for="file of myUser.customer.supplyContractFile">
          <span>Файл</span>
          <div class="input">
            <input type="text"
                   placeholder=""
                   v-model="file.filename"
                   disabled
            >
            <button v-if="file && file.filename" @click="getFile(file)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr class="line">
      <div class="user-requisites" v-if="myUser && myUser.name">
        <span class="user-requisites__head">Файлы поручительства</span>
        <div class="input-wrap" v-for="file of myUser.customer.suretyFile">
          <span>Файл</span>
          <div class="input">
            <input type="text"
                   placeholder=""
                   v-model="file.filename"
                   disabled
            >
            <button v-if="file && file.filename" @click="getFile(file)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr class="line">
      <div class="user-requisites" v-if="myUser && myUser.name">
        <div class="input-wrap">
          <span>Устав</span>
          <div class="input">
            <input v-if="myUser.customer.charterFiles && myUser.customer.charterFiles.filename"
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.charterFiles.filename"
                   disabled
            >
            <input v-else
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.charterFiles"
                   disabled
            >
            <button v-if="myUser.customer.charterFiles && myUser.customer.charterFiles.filename" @click="getFile(myUser.customer.charterFiles)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="input-wrap">
          <span>Свидетельство о постановке на учет</span>
          <div class="input">
            <input v-if="myUser.customer.certificateOfRegistrationFile && myUser.customer.certificateOfRegistrationFile.filename"
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.certificateOfRegistrationFile.filename"
                   disabled
            >
            <input v-else
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.certificateOfRegistrationFile"
                   disabled
            >
            <button v-if="myUser.customer.certificateOfRegistrationFile && myUser.customer.certificateOfRegistrationFile.filename" @click="getFile(myUser.customer.certificateOfRegistrationFile)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="input-wrap">
          <span>Выписка из ЕГРЮЛ</span>
          <div class="input">
            <input v-if="myUser.customer.extractFromEgryulFile && myUser.customer.extractFromEgryulFile.filename"
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.extractFromEgryulFile.filename"
                   disabled
            >
            <input v-else
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.extractFromEgryulFile"
                   disabled
            >
            <button v-if="myUser.customer.extractFromEgryulFile && myUser.customer.extractFromEgryulFile.filename" @click="getFile(myUser.customer.extractFromEgryulFile)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="input-wrap">
          <span>Приказ/решение о назначении директора</span>
          <div class="input">
            <input v-if="myUser.customer.directorOrderFile && myUser.customer.directorOrderFile.filename"
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.directorOrderFile.filename"
                   disabled
            >
            <input v-else
                   type="text"
                   placeholder=""
                   v-model="myUser.customer.directorOrderFile"
                   disabled
            >
            <button v-if="myUser.customer.directorOrderFile && myUser.customer.directorOrderFile.filename" @click="getFile(myUser.customer.directorOrderFile)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr class="line line__last">
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/tabs.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "AccountManagementRequisites",
  components: {
    Tabs
  },
  data() {
    return {
      firstTabActive: false,
      tabsNames: [
        { name: 'Реквизиты', linkName: 'accountManagement.requisites' },
        { name: 'Топливные карты', linkName: 'accountManagement.fuelCards' }
      ],
    }
  },
  computed: {
    ...mapState({
      myUser: state => state.user.myUser,
    }),
  },
  watch: {
  },
  methods: {
    ...mapActions({
      getCustomerById: 'getCustomerById',
      getUsersSearch: 'getUsersSearch',
      getFileByType: 'getFileByType',
    }),
    getAvatarText() {
      let text = '';
      if (this.myUser && this.myUser.name) {
        let nameSplit = this.myUser.name.split(' ')
        nameSplit.forEach((item, index) => {
          if (index < 2) {
            text += item[0];
          }
        })
      }
      return text;
    },
    async getFile(file) {
      let data = {};
      data = {
        file_type: file.file_type,
        id: file.id,
        fileName: file.filename
      }
      await this.getFileByType(data)
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.tab-content {
  margin-top: 30px;
  overflow: auto;
  height: calc(100% - 160px);
  @media screen and (max-width: 1000px) {
    overflow: unset;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

}

.user {
  display: flex;
  align-items: center;

  .avatar {
    position: relative;
    width: 56px;
    height: 56px;
    margin-right: 24px;
    border-radius: 50%;
    overflow: hidden;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(109, 181, 52, 0.1);
    }

    button {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      padding: 4px;
      border: none;
      border-radius: 32px;
      background: #FFFFFF;
      box-shadow: 0 6px 16px 0 rgba(21, 21, 22, 0.16);
      outline: none;
      z-index: 2;
    }
  }

  .name-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .name {
    color: $black;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  .role {
    color: $grey_2;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .avatar {
      margin: 0;
    }

    .name-wrap {
      align-items: center;
    }
  }
}

.line {
  margin: 32px 0 40px;

  &__last {
    margin: 25px 0 0;
  }
}

.user-requisites {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 10px;

  &__head {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
  }

  .input-wrap {
    //width: 49%;
    width: calc(100% / 2 - 5px);
    margin-bottom: 15px;
    .input {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      input {
        //width: calc(100% - 32px);
        width: 100%;
        box-sizing: border-box;
        padding: 8px 12px;
        color: $black;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-radius: 4px;
        border: 1px solid #C1C7CF;
        background: #ffffff;
        outline: none;
      }

      button {
        position: absolute;
        top: 50%;
        right: 10px;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        outline: none;
        transform: translateY(-50%);
      }
    }
    input {
      padding-right: 30px !important;
      text-overflow: ellipsis !important;
    }
  }

  @media screen and (max-width: 800px) {
    .input-wrap {
      width: 100%;
    }
  }
}
</style>
