export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS'
export const SET_TRANSACTION_REPORT = 'SET_TRANSACTION_REPORT'
export const SET_PAYMENT_REPORT = 'SET_PAYMENT_REPORT'
export const SET_CLIENT_PROVIDERS = 'SET_CLIENT_PROVIDERS'
export const SET_CLIENT_PROVIDERS_REGION = 'SET_CLIENT_PROVIDERS_REGION'
export const SET_CLIENT_PROVIDERS_FUEL = 'SET_CLIENT_PROVIDERS_FUEL'
export const SET_CARDS_REPORT = 'SET_CARDS_REPORT'
export const SET_DISCOUNT_BLOCK_DAY = 'SET_DISCOUNT_BLOCK_DAY'
export const SET_CLIENT_GOLD_GAS = 'SET_CLIENT_GOLD_GAS'
export const SET_CLIENT_PARTNER_GAS = 'SET_CLIENT_PARTNER_GAS'
export const SET_DEBT_REPORT = 'SET_DEBT_REPORT'
