import * as type from './types'
import actions from './actions'
import api from '../../../api'
import { SET_CARDS_REPORT } from './types'

const state = {
  transaction_report: null,
  discountBlockDay: null,
  goldGas: null,
  partnerGas: null,
  payment_report: null,
  client_details: null,
  clientProviders: null,
  clientProvidersFuel: null,
  clientProvidersRegion: null,
  cardsReport: null,
  debtReport: null
}

const mutations = {
  [type.SET_CLIENT_DETAILS](state, payload) {
    state.client_details = payload
  },
  [type.SET_TRANSACTION_REPORT](state, payload) {
    console.log('SET_TRANSACTION_REPORT')
    console.log(payload)
    state.transaction_report = payload
  },
  [type.SET_PAYMENT_REPORT](state, payload) {
    state.payment_report = payload
  },
  [type.SET_DISCOUNT_BLOCK_DAY](state, payload) {
    state.discountBlockDay = payload
  },
  [type.SET_CLIENT_GOLD_GAS](state, payload) {
    state.goldGas = payload
  },
  [type.SET_CLIENT_PARTNER_GAS](state, payload) {
    state.partnerGas = payload
  },
  [type.SET_CLIENT_PROVIDERS](state, payload) {
    state.clientProviders = payload
  },
  [type.SET_CLIENT_PROVIDERS_FUEL](state, payload) {
    state.clientProvidersFuel = payload
  },
  [type.SET_CLIENT_PROVIDERS_REGION](state, payload) {
    state.clientProvidersRegion = payload
  },
  [type.SET_CARDS_REPORT](state, payload) {
    let arr = JSON.parse(JSON.stringify(payload))
    arr.data.forEach((item) => {
      item['show_all'] = false
    })
    state.cardsReport = arr
  },
  [type.SET_DEBT_REPORT](state, payload) {
    state.debtReport = payload
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
