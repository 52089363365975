export const getSymbol = (url) => {
  return url.includes('?') ? '&' : '?'
}
export const getFinalUrl = async (url, rootState, enablePagination = true) => {
  let filters = []

  if (rootState.popups.dateRange && rootState.popups.dateRange.str !== '') {
    let additionalFilter = ''
    if (rootState.popups.dateRange.paymentDateRange) {
      additionalFilter = 'payment_'
    }
    if (
      rootState.popups.dateRange.name === 'transactionReportActions' ||
      rootState.popups.dateRange.name === 'reviseReport' ||
      rootState.popups.dateRange.name === 'paymentsActions'
    ) {
      let filter = {
        name:
          rootState.popups.dateRange.name === 'paymentsActions'
            ? `filter[${additionalFilter}date_from]`
            : 'filter[datetime_from]',
        value: ''
      }
      filter.value = rootState.popups.dateRange.withoutTime
        ? `${rootState.popups.dateRange.from}`
        : `${rootState.popups.dateRange.from} 00:00:00`
      filters.push(filter)
      let filter2 = {
        name:
          rootState.popups.dateRange.name === 'paymentsActions'
            ? `filter[${additionalFilter}date_to]`
            : 'filter[datetime_to]',
        value: ''
      }
      filter2.value = rootState.popups.dateRange.withoutTime
        ? `${rootState.popups.dateRange.to}`
        : `${rootState.popups.dateRange.to} 23:59:59`
      filters.push(filter2)
    } else {
      filters.push({
        name: 'date_range',
        value: rootState.popups.dateRange.str
      })
    }
  }

  if (rootState.popups.cardsReportFilters.length > 0) {
    rootState.popups.cardsReportFilters.forEach((item) => {
      if (item.value > 0) {
        filters.push({
          name: item.filter,
          value: item.value
        })
      }
    })
  }

  if (rootState.popups.columns) {
    for (let i = 0; i < rootState.popups.columns.length; i++) {
      let column = rootState.popups.columns[i]
      if (
        !column.multiple_search &&
        column.filter_value &&
        (column.filter_name === 'customer_ids[]' ||
          column.filter_name === 'manager_ids[]' ||
          column.filter_name === 'users_ids[]' ||
          column.filter_name === 'fuel_type_month_ids[]' ||
          column.filter_name === 'fuel_type_year_ids[]' ||
          column.filter_name === 'fuel_type_month' ||
          column.filter_name === 'fuel_type_year' ||
          column.filter_name === 'current_year' ||
          column.filter_name === 'current_month' ||
          column.filter_name === 'fuel_type' ||
          column.filter_name === 'transaction.fuel_type' ||
          column.filter_name === 'fuel_provider_id' ||
          column.filter_name === 'fuel_type_id' ||
          column.filter_name === 'card.fuel_provider_id' ||
          column.filter_name === 'customer_user.id' ||
          column.filter_name === 'customer_id' ||
          column.filter_name === 'transaction.fuel_provider_id'
        )
      ) {
        let filter = {
          name: column.filter_name,
          value: ''
        }
        if (column.filter_name === 'manager_ids[]') {
          filter.value = column.filter_value.map(item => item.manager_id)
        }
        if (column.filter_name === 'customer_ids[]') {
          filter.value = column.filter_value.customer_id
        }
        if (column.filter_name === 'users_ids[]') {
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_month_ids[]') {
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_year_ids[]') {
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_month') {
          //filter.name = `filter[${column.filter_name}]`
          filter.name = `filter[fuel_type_month][]`
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_year') {
          //filter.name = `filter[${column.filter_name}]`
          filter.name = `filter[fuel_type_year][]`
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'current_year') {
          filter.name = `fuel_type_year_ids[]`
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'current_month') {
          filter.name = `fuel_type_month_ids[]`
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type') {
          //filter.name = `filter[fuel_type_id]`
          filter.name = `filter[fuel_type_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'transaction.fuel_type') {
          filter.name = `filter[transaction.fuel_type][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'fuel_provider_id') {
          filter.name = `filter[fuel_provider_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'fuel_type_id') {
          filter.name = `filter[fuel_type_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'card.fuel_provider_id') {
          filter.name = `filter[card.fuel_provider_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'customer_user.id') {
          filter.name = `filter[customer_user.id][]`
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'customer_id') {
          filter.name = `filter[customer_id][]`
          filter.value = column.filter_value.map(item => item.customer_id)
        }
        if (column.filter_name === 'transaction.fuel_provider_id') {
          filter.name = `filter[transaction.fuel_provider_id][]`
          filter.value = column.filter_value
        }
        filters.push(filter)
      }

      if (
        !column.multiple_search &&
        column.filter_value &&
        column.filter_name !== 'customer_ids[]' &&
        column.filter_name !== 'manager_ids[]' &&
        column.filter_name !== 'users_ids[]' &&
        column.filter_name !== 'fuel_type_month_ids[]' &&
        column.filter_name !== 'fuel_type_year_ids[]' &&
        column.filter_name !== 'fuel_type_month' &&
        column.filter_name !== 'fuel_type_year' &&
        column.filter_name !== 'current_year' &&
        column.filter_name !== 'current_month' &&
        column.filter_name !== 'fuel_type' &&
        column.filter_name !== 'transaction.fuel_type' &&
        column.filter_name !== 'fuel_provider_id' &&
        column.filter_name !== 'fuel_type_id' &&
        column.filter_name !== 'card.fuel_provider_id' &&
        column.filter_name !== 'customer_user.id' &&
        column.filter_name !== 'customer_id' &&
        column.filter_name !== 'transaction.fuel_provider_id'
      ) {
        let filter = {
          name: `filter[${column.filter_name}]`,
          value: column.filter_value
        }
        if (column.filter_name === 'manager_user.id') {
          filter.name = `filter[manager_user.id][]`
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'manager_id') {
          filter.name = `filter[manager_id][]`
          filter.value = column.filter_value.map(item => item.manager_id)
        }
        // if (column.filter_name === 'customer_user.id') {
        //   filter.value = column.filter_value.id
        // }
        if (column.filter_name === 'customer_id') {
          filter.value = column.filter_value.customer_id
        }
        if (column.filter_name === 'user_name') {
          filter.value = encodeURIComponent(column.filter_value.name)
        }
        if (column.date_type) {
          let day = String(column.filter_value.getDate()).padStart(2, '0')
          let month = String(column.filter_value.getMonth() + 1).padStart(2, '0') //January is 0!
          let year = column.filter_value.getFullYear()
          filter.value = `${year}-${month}-${day}`
        }
        filters.push(filter)
      }

      if (column.filter_value_from && !column.timepicker) {
        let filter = {
          name: `filter[${column.filter_name_from}]`,
          value: column.filter_value_from
        }
        filters.push(filter)
      } else if (column.filter_value_from && column.timepicker) {
        let filter = {
          name: `filter[${column.filter_name_from}]`,
          value: `${column.filter_value_from.hours
            .toString()
            .padStart(2, '0')}:${column.filter_value_from.minutes.toString().padStart(2, '0')}:00`
        }
        filters.push(filter)
      }
      if (column.filter_value_to && !column.timepicker) {
        let filter = {
          name: `filter[${column.filter_name_to}]`,
          value: column.filter_value_to
        }
        filters.push(filter)
      } else if (column.filter_value_to && column.timepicker) {
        let filter = {
          name: `filter[${column.filter_name_to}]`,
          value: `${column.filter_value_to.hours
            .toString()
            .padStart(2, '0')}:${column.filter_value_to.minutes.toString().padStart(2, '0')}:00`
        }
        filters.push(filter)
      }

      if (column.order) {
        if (column.orderName) {
          filters.push({
            name: 'sortBy',
            value: 'datetime'
          })
        } else if (column.filter_name === 'expired_at_to') {
          filters.push({
            name: 'sortBy',
            value: 'expired_at'
          })
        } else {
          filters.push({
            name: 'sortBy',
            value: column.filter_name ? column.filter_name : column.field
          })
        }

        if (column.filter_name === 'manager_ids[]') {
          filters[filters.length - 1].value = 'manager'
        }
        if (column.filter_name === 'fuel_type_month_ids[]') {
          filters[filters.length - 1].value = 'fuel_type_month'
        }
        if (column.filter_name === 'fuel_type_year_ids[]') {
          filters[filters.length - 1].value = 'fuel_type_year'
        }
        if (column.filter_name === 'users_ids[]') {
          filters[filters.length - 1].value = 'client_name'
        }
        filters.push({
          name: 'sort',
          value: column.order
        })
      }

      if (column.multiple_search) {
        if (column.search_items.length > 0) {
          column.search_items.forEach(item => {
            filters.push({
              name: `filter[${column.filter_name}][]`,
              value: item
            })
          })
        }
        if (column.filter_value) {  
          filters.push({
            name: `filter[${column.filter_name}][]`,
            value: column.filter_value
          })
        }
      }
    }
  }

  if (filters && filters.length > 0) {
    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (Array.isArray(filter.value)) {
        console.log('ARRAYISH filter.value: ', filter.value)
        filter.value.forEach(item => {
          url = `${url}${getSymbol(url)}${filter.name}=${item}`
        })
      } else {
        url = `${url}${getSymbol(url)}${filter.name}=${filter.value}`
      }
    }
  } /* else {
                url = `${url}?page=1&perPage=10`
            }*/

  if (enablePagination) {
    if (rootState.popups.paginationFilters && rootState.popups.paginationFilters.length > 0) {
      for (let i = 0; i < rootState.popups.paginationFilters.length; i++) {
        let filter = rootState.popups.paginationFilters[i]
        url = `${url}${getSymbol(url)}${filter.name}=${filter.value}`
      }
    }
  }

  return url
}

export const getFinalUrl_2 = async (url, rootState, enablePagination = true) => {
  let filters = []

  if (rootState.popups.dateRange && rootState.popups.dateRange.str !== '') {
    if (
      rootState.popups.dateRange.name === 'transactionReportActions' ||
      rootState.popups.dateRange.name === 'reviseReport' ||
      rootState.popups.dateRange.name === 'paymentsActions'
    ) {
      let filter = {
        name:
          rootState.popups.dateRange.name === 'paymentsActions'
            ? 'filter[date_from]'
            : 'filter[datetime_from]',
        value: ''
      }
      filter.value = rootState.popups.dateRange.from
      filters.push(filter)
      let filter2 = {
        name:
          rootState.popups.dateRange.name === 'paymentsActions'
            ? 'filter[date_to]'
            : 'filter[datetime_to]',
        value: ''
      }
      filter2.value = rootState.popups.dateRange.to
      filters.push(filter2)
    } else {
      filters.push({
        name: 'date_range',
        value: rootState.popups.dateRange.str
      })
    }
  }

  if (rootState.popups.columns_2) {
    for (let i = 0; i < rootState.popups.columns_2.length; i++) {
      let column = rootState.popups.columns_2[i]
      if (
        !column.multiple_search &&
        column.filter_value &&
        (column.filter_name === 'customer_ids[]' ||
          column.filter_name === 'manager_ids[]' ||
          column.filter_name === 'users_ids[]' ||
          column.filter_name === 'fuel_type_month_ids[]' ||
          column.filter_name === 'fuel_type_year_ids[]' ||
          column.filter_name === 'fuel_type_month' ||
          column.filter_name === 'fuel_type_year' ||
          column.filter_name === 'current_year' ||
          column.filter_name === 'current_month' ||
          column.filter_name === 'transaction.fuel_type' ||
          column.filter_name === 'fuel_provider_id' ||
          column.filter_name === 'fuel_type_id' ||
          column.filter_name === 'card.fuel_provider_id' ||
          column.filter_name === 'customer_user.id' ||
          column.filter_name === 'customer_id' ||
          column.filter_name === 'transaction.fuel_provider_id'
        )
      ) {
        let filter = {
          name: column.filter_name,
          value: ''
        }
        if (column.filter_name === 'manager_ids[]') {
          filter.value = column.filter_value.map(item => item.manager_id)
        }
        if (column.filter_name === 'customer_ids[]') {
          filter.value = column.filter_value.customer_id
        }
        if (column.filter_name === 'users_ids[]') {
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_month_ids[]') {
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_year_ids[]') {
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_month') {
          //filter.name = `filter[${column.filter_name}]`
          filter.name = `filter[fuel_type_month][]`
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type_year') {
          //filter.name = `filter[${column.filter_name}]`
          filter.name = `filter[fuel_type_year][]`
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'current_year') {
          filter.name = `fuel_type_year_ids[]`
          //filter.value = column.filter_value.id
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'current_month') {
          filter.name = `fuel_type_month_ids[]`
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'fuel_type') {
          //filter.name = `filter[fuel_type_id]`
          filter.name = `filter[fuel_type_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'transaction.fuel_type') {
          filter.name = `filter[transaction.fuel_type][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'fuel_provider_id') {
          filter.name = `filter[fuel_provider_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'fuel_type_id') {
          filter.name = `filter[fuel_type_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'card.fuel_provider_id') {
          filter.name = `filter[card.fuel_provider_id][]`
          filter.value = column.filter_value
        }
        if (column.filter_name === 'customer_user.id') {
          filter.name = `filter[customer_user.id][]`
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'customer_id') {
          filter.name = `filter[customer_id][]`
          filter.value = column.filter_value.map(item => item.customer_id)
        }
        if (column.filter_name === 'transaction.fuel_provider_id') {
          filter.name = `filter[transaction.fuel_provider_id][]`
          filter.value = column.filter_value
        }
        filters.push(filter)
      }

      if (
        !column.multiple_search &&
        column.filter_value &&
        column.filter_name !== 'customer_ids[]' &&
        column.filter_name !== 'manager_ids[]' &&
        column.filter_name !== 'users_ids[]' &&
        column.filter_name !== 'fuel_type' &&
        column.filter_name !== 'fuel_type_month_ids[]' &&
        column.filter_name !== 'fuel_type_year_ids[]' &&
        column.filter_name !== 'fuel_type_month' &&
        column.filter_name !== 'fuel_type_year' &&
        column.filter_name !== 'current_year' &&
        column.filter_name !== 'current_month' &&
        column.filter_name !== 'transaction.fuel_type' &&
        column.filter_name !== 'fuel_provider_id' &&
        column.filter_name !== 'fuel_type_id' &&
        column.filter_name !== 'card.fuel_provider_id' &&
        column.filter_name !== 'customer_user.id' &&
        column.filter_name !== 'customer_id' &&
        column.filter_name !== 'transaction.fuel_provider_id'
      ) {
        let filter = {
          name: `filter[${column.filter_name}]`,
          value: column.filter_value
        }
        if (column.filter_name === 'manager_user.id') {
          filter.name = `filter[manager_user.id][]`
          filter.value = column.filter_value.map(item => item.id)
        }
        if (column.filter_name === 'manager_id') {
          filter.name = `filter[manager_id][]`
          filter.value = column.filter_value.map(item => item.manager_id)
        }
        // if (column.filter_name === 'customer_user.id') {
        //   filter.value = column.filter_value.id
        // }
        if (column.filter_name === 'customer_id') {
          filter.value = column.filter_value.customer_id
        }
        if (column.date_type) {
          let day = String(column.filter_value.getDate()).padStart(2, '0')
          let month = String(column.filter_value.getMonth() + 1).padStart(2, '0') //January is 0!
          let year = column.filter_value.getFullYear()
          filter.value = `${year}-${month}-${day}`
        }
        filters.push(filter)
      }

      if (column.filter_value_from) {
        let filter = {
          name: `filter[${column.filter_name_from}]`,
          value: column.filter_value_from
        }
        filters.push(filter)
      }
      if (column.filter_value_to) {
        let filter = {
          name: `filter[${column.filter_name_to}]`,
          value: column.filter_value_to
        }
        filters.push(filter)
      }

      if (column.order) {
        filters.push({
          name: 'sortBy',
          value: column.filter_name ? column.filter_name : column.field
        })
        if (column.filter_name === 'manager_ids[]') {
          filters[filters.length - 1].value = 'manager'
        }
        if (column.filter_name === 'users_ids[]') {
          filters[filters.length - 1].value = 'client_name'
        }
        filters.push({
          name: 'sort',
          value: column.order
        })
      }

      if (column.multiple_search) {
        if (column.search_items.length > 0) {
          column.search_items.forEach(item => {
            filters.push({
              name: `filter[${column.filter_name}][]`,
              value: item
            })
          })
        }
        if (column.filter_value) {  
          filters.push({
            name: `filter[${column.filter_name}][]`,
            value: column.filter_value
          })
        }
      }
    }
  }

  if (filters && filters.length > 0) {
    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (Array.isArray(filter.value)) {
        console.log('ARRAYISH filter.value: ', filter.value)
        filter.value.forEach(item => {
          url = `${url}${getSymbol(url)}${filter.name}=${item}`
        })
      } else {
        url = `${url}${getSymbol(url)}${filter.name}=${filter.value}`
      }
    }
  } /* else {
                url = `${url}?page=1&perPage=10`
            }*/

  if (enablePagination) {
    if (rootState.popups.paginationFilters_2 && rootState.popups.paginationFilters_2.length > 0) {
      for (let i = 0; i < rootState.popups.paginationFilters_2.length; i++) {
        let filter = rootState.popups.paginationFilters_2[i]
        url = `${url}${getSymbol(url)}${filter.name}=${filter.value}`
      }
    }
  }

  return url
}
