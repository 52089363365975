<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Редактирование транзакции</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Оригинальное
          название ВИНКа
        </div>
        <div class="input">
          <input type="text" v-model="txEdit.original_fuel_provider_name">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Оригинальное
          название региона
        </div>
        <div class="input">
          <input type="text" v-model="txEdit.original_region_name">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Оригинальное
          название типа топлива
        </div>
        <div class="input">
          <input type="text" v-model="txEdit.original_fuel_type_name">
        </div>
      </div>

      <div class="form__item">
        <label class='checkbox-cont'>
          <input v-model="txEdit.partner" class="checkbox" type='checkbox'>
          <span class='indicator'></span>
          <span class="text">Партнер</span>
        </label>
      </div>

    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableSubmitButton" class="button btn-primary" @click="confirmEdit()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
  name: "editTransactionPopup",
  mixins: [popups],
  components: {
    VueDatePicker,
    vSelect
  },
  data: () => ({
    loading: false,
    spinner: true,
    txEdit: {
      original_fuel_provider_name: '',
      original_fuel_type_name: '',
      original_region_name: '',
      partner: false
    }
  }),
  computed: {
    ...mapState({
      transaction: state => state.popups.transaction,
    }),
    disableSubmitButton() {
      return !this.txEdit.original_fuel_provider_name ||
          !this.txEdit.original_fuel_type_name ||
          !this.txEdit.original_region_name
    }
  },
  methods: {
    ...mapActions({
      editTransaction: 'editTransaction',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
    }),
    async confirmEdit() {
      this.loading = true

      await this.editTransaction({id: this.transaction.id, data: this.txEdit})

      this.loading = false
      //this.closeAll()
    }
  },
  mounted() {
    if (this.transaction !== null) {
      this.txEdit.original_fuel_provider_name = this.transaction.original_fuel_provider_name;
      this.txEdit.original_fuel_type_name = this.transaction.original_fuel_type_name;
      this.txEdit.original_region_name = this.transaction.original_region_name;
      this.txEdit.partner = this.transaction.partner;
    }
  }
}
</script>

<style lang="scss">
.input .v-select .vs__search {
  pointer-events: none;
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";
.dp__input {
  border-radius: 6px;
  border: 1px solid $back !important;
  --dp-border-color: $back !important;
}

.popup .form__item .label {
  min-width: 120px;
}

.checkbox-cont {
  //padding: 0 10px;
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  color: #1A1A18;
  font-feature-settings: 'liga' off, 'clig' off;
  //font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
</style>
